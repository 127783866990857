import React,{Component, PureComponent} from "react";
import lodash from 'lodash';

import { Tabs, Tab } from 'react-bootstrap';
import bootbox from 'bootbox';

import ApiClient from './../../common/lib/ApiClient';
import SettingsStore from './../../common/stores/Settings';

import _ from 'lodash';
import moment from 'moment-timezone'

import Notifications from '../../common/lib/Notifications';

import ReactTable from 'react-table';
import CustomPaginationComponent from './../../common/lib/ReactTable-Pagination';
import UserStore from "../../common/stores/User";

import ManageBuild from './manage-build.js';
import classnames from "classnames";

class ResellerBuilds extends React.Component {

    constructor(props) {
        super(props)

        this._handleChange          = this._handleChange.bind(this)
        this._handleFormSubmit      = this._handleFormSubmit.bind(this)
        this.loadData               = this.loadData.bind(this)
        this.handleDelete           = this.handleDelete.bind(this)

        this.state = {
            loaded: false,
            loading: false,
            addBuild: false,
            filters: {},
            manageBuild: null,
            query: ''
        }
    }

    loadData() {
        this.setState({loading: true});

        ApiClient.get({
            endpoint: '/api/v1/build'

        }).then(res => {



            this.setState({
                data: res.body.data,
                loaded: true,
                loading: false
            }, () => this._dataList = this.state.data )

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    _handleSaveSha1Fingerprint (event) {
        if ( event ) { // real form submission
            event.preventDefault();
        }

        this.setState({
            savingForm: true
        });

        let { data } = this.state;
        ApiClient.put({
            endpoint: '/api/v1/system/settings',
            data: { apk_sha1_fingerprint: this.state.new_sha1_fingerprint }
        }).then((res) => {

            // Only if it's success call we alter the data
            var datas = res && res.body && res.body.data ? res.body.data : null;

            Notifications.showNotific8(
                'The system settings have been updated',
                'success', {
                    closeInSeconds: 10
                });

            let {data} = this.state;
            if ( !data ) {
                data = {};
            }
            lodash.assign ( data, { apk_sha1_fingerprint: datas.apk_sha1_fingerprint } )

            this.setState ( { data: data, new_sha1_fingerprint: null, savingForm: false, dataChanged: false }  );

            SettingsStore.setConfig( data );

        }, (err) => {
            ApiClient.onError(err)
        });
    }

    _handleFormSubmit (event) {
        if ( event ) { // real form submission
            event.preventDefault();
        }

        this.setState({
            savingForm: true
        });

        let { data } = this.state;
        ApiClient.put({
            endpoint: '/api/v1/system/settings',
            data: data
        }).then((res) => {

            // Only if it's success call we alter the data
            var data = res && res.body && res.body.data ? res.body.data : null;

            Notifications.showNotific8(
                'The system settings have been updated',
                'success', {
                    closeInSeconds: 10
                });

            this.setState({
                data: data,
                savingForm: false,
                dataChanged: false
            })

            SettingsStore.setConfig( data );

        }, (err) => {
            ApiClient.onError(err)
        });
    }

    _handleChange(event, callback) {
        let value = {};
        value[event.target.name] = event.target.value;

        let {data} = this.state;
        if ( !data ) {
            data = {};
        }
        lodash.assign ( data, value )

        this.setState ( { data: data, dataChanged: true }, callback  );
    }

    _handleSha1Change(event) {
        let new_sha1_fingerprint = event.target.value;
        new_sha1_fingerprint = lodash.toUpper( new_sha1_fingerprint );
        this.setState({ new_sha1_fingerprint });
    }

    handleDelete (id) {

        bootbox.confirm({
            title: 'Confirm reseller build removal',
            message: "Are you sure you want to delete this reseller build? The action can not be undone.",
            callback: function(result) {

                if ( result ) {
                    ApiClient.delete({
                        endpoint: '/api/v1/build/' + id

                    }).then(function(res) {
                        this.loadData();

                    }.bind(this), (err) => {
                        ApiClient.onError(err)
                    })
                }

            }.bind(this),

            buttons: {
                cancel: {
                    label: 'Cancel',
                    className: 'btn-default'
                },
                confirm: {
                    label: 'Delete',
                    className: 'btn-danger'
                }
            }
        });
    }

    onDrop (files) {

        if (!files || !files[0]) {
            return false;
        }

        const req = ApiClient.getPostRequestObject('/api/v1/system/settings/logo');
        req.attach('site_logo', files[0]);

        req.end((err, res) => {
            if ( err || !res.ok ) {
                Notifications.showNotific8(
                    'The new logo could not be uploaded: ' + (res.body && res.body.error ? res.body.error : 'Server error' ),
                    'error', {
                        closeInSeconds: 10
                    });

                return true;
            }

            this._handleChange({
                target: {
                    name: 'site_logo',
                    value: res.body.data.site_logo
                }
            })
        })
    }

    manageBuild(data) {
        this.setState( { manageBuild: data } )
    }

    getTableColumns() {


        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        const columns = [
            {
                Header: 'User',
                sortable: true,
                accessor: 'user_id',
                filterable: true,
                filterAll: true,
                filterMethod: (filter, rows) => {
                    return _.filter( rows, (o) => { return -1 != o.user.data.username.toLowerCase().indexOf ( filter.value.toLowerCase() ) } );
                },
                Cell: ({row}) => {
                    return ( <span>{row._original.user.data ? row._original.user.data.username : '-'}</span> )
                }
            },
            {
                Header: 'Package name',
                sortable: true,
                accessor: 'package_name',
                filterable: true
            },
            {
                Header: 'Package details',
                sortable: false,
                accessor: 'package_meta',
                filterable: false,
                Cell: ({row}) => {
                    return (
                        <div>
                            <span>
                                <strong>Version:</strong> {row._original.package_meta ? row._original.package_meta.apk_version : '-'}
                                {row._original.package_meta && row._original.package_meta.apk_version_code ? ' (' + row._original.package_meta.apk_version_code + ')' : ''}
                                </span>
                        </div>)
                }
            },
            {
                Header: 'Last update',
                sortable: false,
                accessor: 'last_update',
                filterable: false,
                filterAll: true,
                Cell: row => {

                    // Add last login
                    let last_update = 'never'
                    if ( row.original.last_update ) {
                        let c = moment.unix(row.original.last_update).tz(timezone);
                        last_update = c.format('HH:mm MMM D, YYYY');
                    }

                    return (
                        <div>{last_update}</div>
                    )}
            },

            {
                Header: '',
                sortable: false,
                accessor: 'id',
                width: 200,
                Cell: row => <RowActions id={row.value}
                                         data={row.original}
                                         onEdit={() => { this.manageBuild(row.original) }}
                                         onDelete={this.handleDelete} />
            }];

        return columns;
    }

    componentDidMount() {
        document.title = "Reseller Builds | " + SettingsStore.getConfig().site_name;

        this.loadData();
    }


    componentWillUnmount() {

    }

    render() {

        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        let {data, dataChanged} = this.state;
        let dropzoneRef, dropzoneIos;

        let dataList = this.state.data;

        const { history } = this.props;

        const columns = this.getTableColumns();

        const refreshCx = classnames({
            'fa-spin': this.state.loading,
            'far fa-sync': true
        })

        return (
            <div className="content page-settings">
                <div className="clearfix">
                    <div className="help-block text-right pull-left marginB half"
                         style={{ visibility: dataChanged ? 'visible' : 'hidden', color: '#e74c3c'}}>
                        <i className="fa fa-exclamation-triangle fa-fw innerR half"/>
                        There are unsaved changes.
                    </div>
                </div>


                <Tabs defaultActiveKey={1} transition={false} id="reseller-builds">

                    <Tab eventKey={1} title={(<span>Android builds</span>)}>

                        <div className="clearfix">
                            <button className="btn btn-pagination btn-sm pull-left marginR"
                                    disabled={this.state.loading}
                                    onClick={this.loadData}><i className={refreshCx}/></button>

                            <button className="btn btn-warning btn-sm marginL pull-right" onClick={this.manageBuild.bind(this,{})}>
                                <i className="fal fa-plus marginR half"></i> Add new build
                            </button>
                        </div>

                        {null === this.state.manageBuild ? null :
                            <ManageBuild data={this.state.manageBuild}
                                         onChanged={() => { this.setState({manageBuild: null}, () => this.loadData() ) }}
                            />
                        }

                        <ReactTable
                            PaginationComponent={CustomPaginationComponent}
                            showPageSizeOptions={true}
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            className=""
                            paginationClassName="pagination-filters clearfix"
                            data={dataList}
                            columns={columns}
                            defaultPageSize={10}
                            minRows={1}
                            loading={!this.state.loaded}
                        />
                    </Tab>

                </Tabs>


            </div>
        );
    }
}

export default ResellerBuilds

class RowActions extends Component {

    render() {

        let { id, data } = this.props
        return (
            <div className="">

                {data && data.package_meta && data.package_meta.apk_url ?
                <a href={data.package_meta.apk_url} target="_blank"
                   className="text-success innerR half marginR">Download</a>
                    : null}

                <a href="javascript:;"
                   className="text-primary innerR half marginR"
                   onClick={(event) => this.props.onEdit( id ) }>Update</a>

                <a href="javascript:;"
                   className="text-danger"
                   onClick={(event) => this.props.onDelete( id ) }>Delete</a>
            </div> );

    }
}