import React from 'react';
import { Link } from 'react-router-dom';
import AdminAreaRouter from './AdminAreaRouter'

export default class NavbarOwn extends React.Component {

    render() {

        const {appConfig} = this.props;

        return (
            <div id="sidebar-wrapper">
                <nav id="main-navigation">
                    <div className="sidebar-header">

                            <Link to="/">
                                {appConfig.site_logo ?<img src={appConfig.site_logo} title={appConfig.site_name} className="img-responsive" /> : null }
                                <span>{appConfig.site_name}</span>
                            </Link>

                    </div>

                    <ul className="sidebar-nav nav">
                        <li>
                            <Link to="/dashboard">
                                <i className="fal fa-tachometer-alt fa-fw marginR half"/> Dashboard
                            </Link>
                        </li>
                        <AdminAreaRouter>
                        <li>
                            <Link to="/users">
                                <i className="fal fa-user fa-fw marginR half"/> Customers
                            </Link>
                        </li>
                        </AdminAreaRouter>
                        <li>
                            <Link to="/subscriptions">
                                <i className="fal fa-comments-alt fa-fw marginR half"/> Accounts
                            </Link>
                        </li>

                        {/*
                        <AdminAreaRouter>
                            <li>
                                <Link to="/system/reseller-builds">
                                    <i className="fal fa-cloud-download fa-fw marginR half"/> Reseller builds
                                </Link>
                            </li>
                        </AdminAreaRouter>
                        */}

                        <AdminAreaRouter>
                            <li>
                                <Link to="/debuglog">
                                    <i className="fal fa-debug  fa-fw marginR half"/> Debug logs
                                </Link>
                            </li>
                        </AdminAreaRouter>

                        {!appConfig.analytics_enabled ? null :
                        <AdminAreaRouter>
                            <li>
                                <a href="javascript:;"><i className="fal fa-chart-area fa-fw marginR half"/>  Reports</a>
                                <ul className="show sidebar-submenu" id="reportsSubmenu">
                                    <li>
                                        <Link to="/analytics/active-users">
                                            Active Users
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/analytics/statistics">
                                            Statistics
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/analytics/activity">
                                            Regional Activity
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/analytics/report/app-version">
                                            Charts
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/analytics/event-log">
                                            Event Log
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </AdminAreaRouter>
                        }

                        <AdminAreaRouter>
                            <li>
                                <Link to="/system/broadcast">
                                    <i className="fal fa-megaphone fa-fw marginR half"/> Announcements
                                </Link>
                            </li>
                        </AdminAreaRouter>

                        <AdminAreaRouter>
                            <li>
                                <Link to="/system/settings">
                                    <i className="fal fa-wrench fa-fw marginR half"/> System settings
                                </Link>
                            </li>
                        </AdminAreaRouter>
                    </ul>
                </nav>
            </div>
        )
    }
}
