import React,{Component} from "react";
import { Link } from 'react-router-dom';
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalTitle from 'react-bootstrap/ModalTitle';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import AdminAreaRouter from './../../common/components/AdminAreaRouter';

import ApiClient from './../../common/lib/ApiClient';
import SubscriptionsStore from './../../common/stores/Subscriptions';
import UserStore from './../../common/stores/User';
import SettingsStore from './../../common/stores/Settings';

import _ from 'lodash';
import bootbox from 'bootbox';
import classnames from 'classnames'
import moment from 'moment-timezone'
import qs from 'qs'
import { debounce } from "debounce"

import ReactTable from 'react-table';
import CustomPaginationComponent from './../../common/lib/ReactTable-Pagination';

const DragHandle = SortableHandle(() => <div className="move-handler"><i className="fa fa-bars"></i></div>);

const SortableItem = SortableElement(({data, index, onCheck}) =>
    <li className="list-group-item d-flex">
        <DragHandle />
        <div className="column-checked">
            <input type="checkbox"
                   name={data.column_name}
                   checked={!!data.show_column}
                   onChange={onCheck.bind(this,data)} />
        </div>
        <div className="column-name">{data.label}</div>
    </li>
);

const SortableList = SortableContainer(({data, enabled, onCheck}) => {
    return (
        <ul className="table-columns-list list-group marginB half">
        {data.map(function(item,index) {
            return (
                <SortableItem key={'tcla-' + index}
                              index={index}
                              data={item}
                              onCheck={onCheck} />
            )
        })}
    </ul>
    );
});

export default class SubscriptionsPage extends React.Component {

    constructor(props) {
        super(props)

        this.getSubscriptions   = this.getSubscriptions.bind(this)
        this.fetchData          = this.fetchData.bind(this)
        //this.fetchData          = debounce(this.fetchData, 200)

        this._onSearchChange    = this._onSearchChange.bind(this)
        this.handleDelete       = this.handleDelete.bind(this)
        this.handleStatusChange = this.handleStatusChange.bind(this)
        this.handleDoNotListChange = this.handleDoNotListChange.bind(this)
        this.showColumnsEditor  = this.showColumnsEditor.bind(this)
        this.hideColumnsEditor  = this.hideColumnsEditor.bind(this)
        this.handleColumnStatusChange = this.handleColumnStatusChange.bind(this)

        this.handleSaveTableLayout    = this.handleSaveTableLayout.bind(this)

        this.state = {
            loaded: false,
            loading: false,
            loadingRow: null,
            editColumns: false,
            columnsSaved: false,
            availableAttributes: [],
            availableColumns: [],
            tableColumnsConfig: [],
            filters: {},
            query: ''
        };
    }

    getSubscriptions() {
        this.setState({loading: true});

        ApiClient.get({
            endpoint: '/api/v1/subscription',
            query: {
                include: 'user,attributes',
                q: this.state.query
            }

        }).then(res => {

            this._dataList = res.body.data;

            this.setState({
                data: res.body.data,
                loaded: true,
                loading: false
            }, function() {
                let { data } = this.state;
                SubscriptionsStore.setData( data );
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    fetchData(state, instance) {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({ loading: true });

        let filters = qs.stringify({f: state.filtered}, { arrayFormat: 'indices', encodeValuesOnly: true });
        let order = qs.stringify({o: state.sorted}, { arrayFormat: 'indices', encodeValuesOnly: true });

        let request = ApiClient.get({
            endpoint: '/api/v1/subscription',
            query: {
                include: 'user,attributes',
                q: this.state.query,
                p: state.page,
                l: state.pageSize
            }

        });

        if ( filters ) {
            request.query( filters );
        }

        if ( order ) {
            request.query( order );
        }

        request.then(res => {

            this._dataList = res.body.data;

            /*this.setState({
                data: res.rows,
                pages: res.pages,
                loading: false
            });*/

            this.setState({
                data: res.body.data,
                pages: res.body.pages,
                loaded: true,
                loading: false
            }, function() {
                let { data } = this.state;
                SubscriptionsStore.setData( data );
            })

        }, (err) => {
            ApiClient.onError(err)
            this.setState({
                loading: false
            })
        })
    }

    _onSearchChange(e) {
        if (!e.target.value) {
            this.setState({
                data: this._dataList,
            });
        }

        let filterBy = e.target.value.toLowerCase();

        let newDataList = _.filter(this._dataList, function(i) {
            let {username, first_name, last_name } = i;
            return ( username && username.toLowerCase().indexOf(filterBy) !== -1 )
                || ( first_name && first_name.toLowerCase().indexOf(filterBy) !== -1 )
                ||  ( last_name && last_name.toLowerCase().indexOf(filterBy) !== -1 );
        });

        this.setState({
            data: newDataList,
            q: filterBy
        });
    }

    handleDoNotListChange( id, data, newstatus, index ) {

        this.setState({loadingRow: index})
        data.do_not_list = newstatus;

        ApiClient.put({
            endpoint: '/api/v1/subscription/' + id + '/do-not-list',
            query: { include: 'user' },
            data: data
        }).then((res) => {
            //this.getSubscriptions()
            this.setState({loadingRow: null})
        }, (err) => {
            ApiClient.onError(err)
            this.setState({loadingRow: null})
        });
    }

    handleSaveTableLayout() {
        ApiClient.put({
            endpoint: '/api/v1/system/settings',
            data: {
                subscriptions_table_layout: this.state.tableColumnsConfig
            }
        }).then((res) => {

            // Only if it's success call we alter the data
            let data = res && res.body && res.body.data ? res.body.data : null;

            // Merge existing state with new data
            let settings = SettingsStore.getConfig();
            settings.subscriptions_table_layout = data.subscriptions_table_layout;

            /*Notifications.showNotific8(
                'The table columns configurations has been been updated',
                'success', {
                    closeInSeconds: 10
                });*/

            this.setState({
                dataChanged: false,
                columnsSaved: true,
                tableColumnsConfig: _.clone(settings.subscriptions_table_layout)
            })

            SettingsStore.setConfig( settings );

        }, (err) => {
            ApiClient.onError(err)
        });
    }

    handleStatusChange( id, data, newstatus, index ) {
        this.setState({loadingRow: index})
        data.is_active = newstatus;

        ApiClient.put({
            endpoint: '/api/v1/subscription/' + id + '/status',
            query: { include: 'user' },
            data: data
        }).then((res) => {
            //this.getSubscriptions()
            this.setState({loadingRow: null})
        }, (err) => {
            ApiClient.onError(err)
            this.setState({loadingRow: null})
        });
    }

    handleColumnStatusChange(data, event) {

        let tableColumnsConfig = _.clone(this.state.tableColumnsConfig);

        let idx = _.findIndex(tableColumnsConfig, { column_name: data.column_name } );

        if ( -1 == idx) {
            tableColumnsConfig.push( data );
            idx = tableColumnsConfig.length ? tableColumnsConfig.length - 1 : 0;
        }

        tableColumnsConfig[idx].show_column = event.target.checked ? 1 : 0;

        this.setState({tableColumnsConfig: tableColumnsConfig}, this.handleSaveTableLayout)
    }

    handleColumnsSortEnd({oldIndex, newIndex}) {
        const newOrder = arrayMove(this.state.tableColumnsConfig, oldIndex, newIndex);

        this.setState({
            tableColumnsConfig: newOrder
        }, this.handleSaveTableLayout);
    }

    handleDelete (id, data) {

        const username = data.sip_username;

        bootbox.confirm({
            title: 'Confirm account removal',
            message: "Are you sure you want to delete the account <strong>" + username + "</strong>? <br/><br/>This action can not be undone.",
            callback: function(result) {

                if ( result ) {
                    ApiClient.delete({
                        endpoint: '/api/v1/subscription/' + id

                    }).then(function(res) {
                        this.getSubscriptions()


                    }.bind(this), (err) => {
                        ApiClient.onError(err)
                    })
                }

            }.bind(this),

            buttons: {
                cancel: {
                    label: 'Cancel',
                    className: 'btn-default'
                },
                confirm: {
                    label: 'Delete',
                    className: 'btn-danger'
                }
            }
        });
    }

    componentWillMount() {
        this.prepareColumnsAttributes();
    }

    componentDidMount() {
        document.title = "Accounts | " + SettingsStore.getConfig().site_name;

        /*const Subscriptions = SubscriptionsStore.getSubscriptions();
        this.setState({
            data: Subscriptions
        }, () => this.getSubscriptions());*/
    }

    showColumnsEditor() {
        this.setState({
            editColumns: true
        })
    }

    hideColumnsEditor() {
        this.setState({
            editColumns: false
        })
    }

    prepareColumnsAttributes() {
        // Init available table columns
        let availableAttributes = SettingsStore.getConfig().subscriptions_table_available_columns;

        // Table specific attributes
        availableAttributes.push({column_name : 'user_id', label: 'Customer'});
        availableAttributes.push({column_name : 'device_imei', label: 'IMEI'});
        availableAttributes.push({column_name : 'software_version', label: 'Software Version'});
        /*availableAttributes.push({column_name : 'subscription_start', label: 'Start Date'});
        availableAttributes.push({column_name : 'subscription_end', label: 'End Date'});*/
        availableAttributes.push({column_name : 'is_active', label: 'Status'});
        availableAttributes.push({column_name : 'voip_status', label: 'Connection status'});

        let {subscriptions_table_layout} = SettingsStore.getConfig();
        let tableLayout = _.clone( subscriptions_table_layout );
        let attributesNotInLayout = [];

        if ( !tableLayout ) {
            tableLayout = [];

        } else {
            let tableLayoutFiltered = [];
            // Remove from the table layout any columns which are not in the available attributes list
            for ( let i=0;i<tableLayout.length;i++ ) {
                if (-1 == _.findIndex(availableAttributes, {column_name: tableLayout[i].column_name})) {
                    continue;
                }

                tableLayoutFiltered.push ( tableLayout[i] );
            }

            tableLayout = tableLayoutFiltered;
        }

        let availableCols = [];
        // First we add the table layout columns
        availableCols = _.clone(tableLayout);

        for ( let i=0; i < availableAttributes.length; i++ ) {
            if (-1 != _.findIndex(tableLayout, {column_name: availableAttributes[i].column_name})) {
                continue;
            }

            tableLayout.push( availableAttributes[i] );
        }

        this.setState ( {
            availableAttributes: availableAttributes,
            availableColumns: availableCols,
            tableColumnsConfig: tableLayout
        } );
    }

    getTableColumns() {
        const users = _.uniqBy(this._dataList, 'user_id')

        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        let settings = SettingsStore.getConfig();

        const columns = [
            {
                Header: 'Username',
                sortable: true,

                accessor: 'sip_username',
                minWidth: 170,
                filterable: true,
                filterAll: true,
                filterMethod: (filter, rows) => {
                    return _.filter( rows, (o) => { return -1 != o.sip_username.toLowerCase().indexOf ( filter.value.toLowerCase() ) } );
                },

                Cell: ({row, original}) => {
                    let licenseKey = null;

                    if ( settings.use_license_key ) {
                        licenseKey = (<div className="last-login help-block">License Key: <span className=""> {original.unique_license_code}</span></div>)
                    }
                    return ( <div>
                        <span className="text-weight-500">{original.sip_username}</span>
                        {licenseKey}
                    </div> )
                }
            }];

        const tableLayout = SettingsStore.getConfig().subscriptions_table_layout;
        const tableLayoutAvailable = SettingsStore.getConfig().subscriptions_table_available_columns;

        if ( tableLayout ) {
            let startLength = tableLayout.length;
            for ( let i = 0; i < startLength; i++ ) {
                let col = tableLayout[i];

                // Is it enabled?
                if ( !col.show_column || -1 == _.findIndex(tableLayoutAvailable, {column_name: col.column_name } ) ) {
                    continue;
                }

                // Do we still have this attribute in the system?


                if ( typeof col.is_attribute != "undefined" && col.is_attribute ) {
                    if ( 'customer_name' == col.column_name ) {
                        columns.push({
                            Header: col.label,
                            sortable: false,
                            accessor: 'attributes_' + col.column_name,
                            filterable: true,

                            Cell: ({row, original}) => {
                                const attribute = _.find(original.attributes.data, { 'field_name': col.column_name });

                                if ( !attribute ) {
                                    return null;
                                }

                                return ( <div className="custom-attribute">{attribute.value}</div> )
                            }
                        });

                        continue;
                    }


                    // Do something else
                    columns.push({
                        Header: col.label,
                        sortable: false,

                        accessor: 'attributes_' + col.column_name,

                        Cell: ({row, original}) => {
                            const attribute = _.find(original.attributes.data, { 'field_name': col.column_name });

                            if ( !attribute ) {
                                return null;
                            }

                            return ( <div className="custom-attribute">{attribute.value}</div> )
                        }
                    })

                    continue;
                }

                // Add from hard coded columns

                if ( 'user_id' == col.column_name ) {
                    columns.push({
                        Header: 'Customer',
                        accessor: 'user_id',
                        sortable: true,
                        filterable: true,
                        filterMethod: (filter, row) => {
                            if (filter.value === 'all') {
                                return true
                            }

                            if (parseInt(filter.value) === row.user_id) {
                                return true
                            }

                            return false
                        },
                        Filter: ({ filter, onChange }) => {
                            return(<select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%' }}
                                value={filter ? filter.value : 'all'}
                            >
                                <option value="all">- all users -</option>
                                {users.map(function(d) {
                                    return (<option key={'rtfu' + d.user.data.id} value={d.user.data.id}>{d.user.data.username}</option>)
                                })}
                            </select>)
                        },

                        Cell: ({row}) => {
                            return ( <span>{row._original.user.data ? row._original.user.data.username : '-'}</span> )
                        }
                    })

                    continue;
                }

                if ( 'subscription_start' == col.column_name ) {
                    columns.push({
                        Header: 'Start Date',
                        sortable: true,
                        accessor: 'subscription_start',
                        className: 'text-center',
                        headerClassName: 'text-center',
                        width: 140,
                        Cell: row => {
                            if ( !row.value ) {
                                return null;
                            }

                            var c = moment.unix(row.value).tz(timezone);
                            return ( <span>{c.format('MMM D, YYYY')}</span> )
                        }
                    });

                    continue;
                }

                if ( 'subscription_end' == col.column_name ) {
                    columns.push({
                        Header: 'End Date',
                        sortable: true,
                        accessor: 'subscription_end',
                        className: 'text-center',
                        headerClassName: 'text-center',
                        width: 140,
                        Cell: row => {
                            if ( !row.value ) {
                                return null;
                            }

                            let m = moment.unix( row.value );

                            // prepare classname
                            let cx = classnames({"text-danger": m.isBefore(new Date())})

                            var c = moment.unix(row.value).tz(timezone);
                            return ( <span className={cx}>{c.format('MMM D, YYYY')}</span> )
                        }
                    });

                    continue;
                }

                if ( 'device_imei' == col.column_name || 'software_version' == col.column_name ) {
                    columns.push({
                        Header: col.label,
                        sortable: false,
                        accessor: col.column_name,
                        filterable: true
                    });

                    continue;
                }

                if ( 'is_active' == col.column_name ) {
                    columns.push({
                        Header: 'Status',
                        sortable: false,
                        accessor: 'is_active',
                        className: 'text-center',
                        headerClassName: 'text-center',
                        width: 130,
                        filterable: true,
                        filterMethod: (filter, row) => {
                            if (filter.value === 'all') {
                                return true
                            }

                            return row.is_active == parseInt(filter.value);
                        },
                        Filter: ({ filter, onChange }) => {
                            return(<select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%' }}
                                value={filter ? filter.value : 'all'}
                            >
                                <option value="all">All</option>
                                <option value="1">Active</option>
                                <option value="0">Suspended</option>
                            </select>)
                        },
                        Cell: row => (
                            <span>{row.value ? 'Active' : 'Suspended'}</span>
                        )
                    });

                    continue;
                }

                if ( 'voip_status' == col.column_name ) {
                    columns.push({
                        Header: 'Online',
                        sortable: false,
                        accessor: 'voip_status',
                        className: 'text-center',
                        headerClassName: 'text-center',
                        width: 100,
                        filterable: true,
                        filterMethod: (filter, row) => {
                            if (filter.value === 'all') {
                                return true
                            }

                            return row.voip_status == (filter.value);
                        },
                        Filter: ({ filter, onChange }) => {
                            return(<select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%' }}
                                value={filter ? filter.value : 'all'}
                            >
                                <option value="all">All</option>
                                <option value="online">Online</option>
                                <option value="offline">Offline</option>
                            </select>)
                        },
                        Cell: (row) => {
                            let el = null;
                            if ('online' == row.value ) {
                                el = (
                                    <span className="voip-status text-success" title={'Online'}><i className="fal fa-globe"/></span>
                                )
                            }
                            if ('offline' == row.value ) {
                                el = (
                                    <span className="voip-status text-faded" title={'Offline'}><i className="fal fa-globe"/></span>
                                )
                            }
                            return el;
                        }
                    });

                    continue;
                }
            }
        }

        // Add last column the subscription ID for the buttons
        columns.push({
            Header: '',
            sortable: false,
            accessor: 'id',
            width: 170,
            className: 'col-with-dropdown',
            Cell: row => { return (<RowActions id={row.value} is_active={row.original.is_active}
                                     data={row.original}
                                     index={row.index}
                                     loadingRow={this.state.loadingRow}

                                     do_not_list={row.original.do_not_list}
                                     onDoNotListChange={this.handleDoNotListChange}
                                     onStatusChange={this.handleStatusChange}
                                     onDelete={this.handleDelete} />)}
        });

        return columns;
    }

    render() {

        const users = _.uniqBy(this._dataList, 'user_id')

        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        const columns = this.getTableColumns();

        let dataList = this.state.data;
        const { data, pages, loading } = this.state;

        const sortedColumns = this.state.tableColumnsConfig;

        const refreshCx = classnames({
            'fa-spin': this.state.loading,
            'far fa-sync': true
        })

        return (
          <div className="content">
            <h1>Accounts</h1>
            <p className="welcomeText"></p>

              <div>
                  <div className="clearfix">
                      <button className="btn btn-pagination btn-sm pull-left marginR"
                              disabled={this.state.loading}
                              onClick={this.getSubscriptions}><i className={refreshCx}/></button>

                      <Link to="/subscription" className="btn btn-warning btn-sm pull-right marginL">
                          <i className="far fa-user-plus marginR half"></i> Add account
                      </Link>

                     <AdminAreaRouter>
                         <div>
                          <button className="btn btn-light btn-sm pull-right marginL" onClick={this.showColumnsEditor}>
                              <i className="fa fa-table marginR half"></i> Table columns
                          </button>

                             <Modal show={this.state.editColumns} onHide={this.hideColumnsEditor}>
                                 <ModalHeader closeButton>
                                     <ModalTitle>&nbsp;Choose table columns</ModalTitle>
                                 </ModalHeader>
                                 <ModalBody>
                                     <div>
                                         <p>Choose which columns you want to be visible in the Subscriptions table. Please note that these settings will apply to all users in the system.</p>
                                         {!sortedColumns ? <div>No columns available for this table</div> :
                                         <SortableList data={sortedColumns}
                                                       enabled={this.state.tableColumnsConfig}
                                                       useDragHandle={true}
                                                       hideSortableGhost={true}
                                                       lockAxis="y"
                                                       lockToContainerEdges={true}
                                                       onCheck={this.handleColumnStatusChange.bind(this)}
                                                       onSortEnd={this.handleColumnsSortEnd.bind(this)}/>
                                         }
                                         {!this.state.columnsSaved ? null : <span className="text-info">The new columns layout has been saved.</span>}
                                         {/*<button className="btn btn-primary btn-sm" onClick={this.handleSaveTableLayout}>Save table layout</button>*/}
                                     </div>
                                 </ModalBody>
                             </Modal>
                         </div>
                     </AdminAreaRouter>

                  </div>

                   <ReactTable
                       manual
                       onFetchData={this.fetchData} // Request new data when things change

                      PaginationComponent={CustomPaginationComponent}
                      showPageSizeOptions={true}
                      pageSizeOptions={[10, 25, 50, 100, 200]}
                      className=""
                      paginationClassName="pagination-filters clearfix"
                      data={dataList}
                      columns={columns}
                      defaultPageSize={10}
                      minRows={1}
                      loading={loading}
                       pages={pages} // Display the total number of pages
                  />

              </div>
          </div>
        );
    }
}

class RowActions extends Component {

    render() {

        let { id, is_active, data, do_not_list, is_loading, index, loadingRow } = this.props


        let dnlcx = classnames({
            "marginR semi-half": true,
            "text-faded": !do_not_list,
            "text-primary": do_not_list
        })

        let scx = classnames({
            "marginR semi-half": true,
            "text-warning": !is_active,
            "text-primary": is_active
        })

        let isDisabled = false;
        if ( data.subscription_end ) {
            let m = moment.unix( data.subscription_end );

            // prepare classname
            isDisabled = m.isBefore(new Date());
        }

        return (
        <div className="row-actions actions-short">
            {index !== loadingRow?null:
            <div className={'loading'}><i className={'fa fa-spin fa-spinner'}/></div>
            }

            <Button variant="link"
                    className="marginR half"
                    title={do_not_list ? "Not listed in contacts list" : "Listed in contacts list"}
                    onClick={(event) => this.props.onDoNotListChange( id, data, !do_not_list, index ) }
            ><i className={do_not_list ? "fal fa-user-secret text-warning" : "fal fa-users text-secondary"}/></Button>


            <DropdownButton variant="link" className="text-warning" title="Manage" alignRight>
                <Dropdown.Item  as={Link} to={"/subscription/" + id}>Edit</Dropdown.Item>
                <Dropdown.Item href="javascript:;"
                               onClick={(event) => this.props.onStatusChange( id, data, !is_active, index ) }>
                    {is_active ? "Suspend" : "Unsuspend"}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="javascript:;"
                               variant="danger"
                               onClick={(event) => this.props.onDelete( id, data ) }>Delete</Dropdown.Item>
            </DropdownButton>

        </div> );

    }
}
