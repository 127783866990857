import jwt_decode from 'jwt-decode';

var EventEmitter = require('events').EventEmitter;
var emitter = new EventEmitter();

var userData = {
    username: '',
    isLoggedIn: false
};

var _jwt = null;
var _isLoggedIn = 0;


export default {
    getUser: function() {
        return userData;
    },

    subscribe: function(callback, event) {
        emitter.on(event ? event : 'update', callback);
    },

    unsubscribe: function(callback, event) {
        emitter.removeListener(event ? event : 'update', callback);
    },

    postLogin: function(data) {
        userData = data;
        emitter.emit('update');
    },

    loginUser: function(jwt, callback) {

        // Log the user in
        let decoded = jwt_decode(jwt);

        userData    = decoded;
        _isLoggedIn = true;
        _jwt        = jwt;

        // We save the JWT in localStorage to keep the user authenticated. We’ll learn more about this later.
        localStorage.setItem('jwt', jwt);

        // Let listeners know the user has logged in
        emitter.emit('update');

        // Execute callback
        if ( callback && typeof callback === 'function' ) {
            callback(jwt);
        }
    },

    logoutUser: function() {

        userData    = {};
        _isLoggedIn = false;
        _jwt        = null;

        // We delete the JWT from local storage
        localStorage.removeItem('jwt');

        // Let listeners know the user has logged out
        emitter.emit('logout');
    },

    getJwt: function() {
        return _jwt;
    },

    isLoggedIn: function() {
        return _isLoggedIn;
    },

    isAdmin: function() {
        if ( userData && typeof userData.user_role != 'undefined' && userData.user_role == 'super-admin' ) {
            return true;
        }

        return false;
    }
};
