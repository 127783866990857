import React from "react";

import Settings from './../../common/stores/Settings';


const AdminWelcome = () => {
    return (
        <div>
            <h1>Welcome!</h1>
            <p className="innerAll half">Welcome to {Settings.getConfig().site_name}</p>
        </div>
    )
}

export default class HomePage extends React.Component {

    componentDidMount() {
        document.title = "Dashboard | " + Settings.getConfig().site_name;
    }

  render() {

    return (
      <div className="content">
          <AdminWelcome/>
      </div>
    );
  }
}
