import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";

class RegionFilter extends React.Component {

    render() {
        const {
            regions,
            selected,
            onChange
        } = this.props;


        return (
            <div className={classNames({
                'filter-regions': true,
            })}>
                <h6 className="text-center">Choose regions</h6>
                <Select name={'filter-region'}
                        blurInputOnSelect={true}
                        components={makeAnimated()}
                        styles={{
                            control: (base) => ({
                                ...base,
                                minHeight: 41,
                            }),
                            multiValueLabel:(base) => ({
                                ...base,
                                padding: '5px 15px',
                                paddingLeft: 10,
                                fontSize: '100%',
                                borderRadius: 5, /*display: 'flex'*/
                            })
                        }}
                        placeholder={'Choose region'}
                        options={regions}
                        className="stats-filter"
                        classNamePrefix="select"
                        isSearchable
                        isClearable={false}
                        isMulti
                        defaultValue={selected}
                        delimiter=","

                        onChange={this.props.onChange}
                />
            </div>
        )
    }
}

RegionFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
}

export default RegionFilter;