import React,{Component, PureComponent} from "react";
import { Link, Redirect } from 'react-router-dom';
import lodash from 'lodash';
import classNames from 'classnames';
import DatePicker from 'react-datepicker'
import moment from 'moment';

import Attributes from './attributes';
import ApiClient from './../../common/lib/ApiClient';
import UserStore from './../../common/stores/User';
import UsersStore from './../../common/stores/Users'

import PageLoading from './../../common/components/PageLoading';
import PageHeading from './../../common/components/PageHeading';
import Notifications from '../../common/lib/Notifications';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class ManageSubscription extends React.Component {

    constructor(props) {
        super(props)

        this._handleChange          = this._handleChange.bind(this)
        this._handleChangeUsername  = this._handleChangeUsername.bind(this)
        this._handleChangeAttribute = this._handleChangeAttribute.bind(this)
        this._handleFormSubmit      = this._handleFormSubmit.bind(this)
        this.postUsersStoreUpdate   = this.postUsersStoreUpdate.bind(this)

        this.state = {
            id: props.match && props.match.params && props.match.params.id ? props.match.params.id : null,
            dataChanged: false,
            users: null,
            passwordConfirmation: '',
            data: {
                is_active: 1
            }
        }
    }

    loadData(userId) {
        if ( !userId ) {
            return false;
        }

        ApiClient.get({
            endpoint: '/api/v1/subscription/' + userId,
            query: {
                include: 'user,attributes'
            }

        }).then(res => {
            this.setState({
                dataChanged: false,
                data: res.body.data,
                passwordConfirmation: '',
                id: res.body.data.id
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    _handleFormSubmit (event) {
        if ( event ) { // real form submission
            event.preventDefault();
        }

        // Let's check if the two passwords match
        if ( !this.state.id ) {
            if ( !this.state.data.sip_password ) {
                var error = 'The password is required when creating an account';
                Notifications.showNotific8(error, 'danger', { closeInSeconds: 6 } );
                return false;
            }
        }

        if ( this.state.data.sip_password && this.state.data.sip_password != this.state.passwordConfirmation ) {
            var error = 'The two passwords do not match';
            Notifications.showNotific8(error, 'danger', { closeInSeconds: 6 } );
            return false;
        }

        this.setState({
            savingForm: true
        });

        let { data } = this.state;

        // What kind of submit?
        if ( this.state.id ) {

            ApiClient.put({
                endpoint: '/api/v1/subscription/' + this.state.id,
                query: { include: 'user,attributes' },
                data: data
            }).then((res) => {

                // Only if it's success call we alter the data
                var data = res && res.body && res.body.data ? res.body.data : null;

                Notifications.showNotific8(
                    'The account data has been saved',
                    'success', {
                        closeInSeconds: 6
                    });

                this.setState({
                    data: data,
                    passwordConfirmation: '',
                    savingForm: false,
                    fireRedirect: true
                })

            }, (err) => {
                ApiClient.onError(err)
            });

        } else {

            const {newPackage} = this.state;
            data.newPackage = newPackage;

            ApiClient.post({
                endpoint: '/api/v1/subscription',
                query: { include: 'user' },
                data: data
            }).then((res) => {

                // Only if it's success call we alter the data
                var data = res && res.body && res.body.data ? res.body.data : null;

                Notifications.showNotific8(
                    'The account has been added to the system',
                    'success', {
                        closeInSeconds: 6
                    });

                this.setState({
                    passwordConfirmation: '',
                    savingForm: false,
                    fireRedirect: true
                }, function() {
                    // Show success

                    // Let parent know about the change
                    if ( this.props.onUserDataChanged ) {
                        this.props.onUserDataChanged( this.state.data );
                    }
                })

            }, (err) => {
                ApiClient.onError(err)
            });
        }
    }

    _handleChangeUsername(event) {
        let value = {};
        value[event.target.name] = event.target.value;
        value[event.target.name] = value[event.target.name].toLowerCase();

        let {data} = this.state;
        if ( !data ) {
            data = {};
        }
        lodash.assign ( data, value )

        this.setState ( { data: data, dataChanged: true }  );
    }

    _handleChange(event) {
        let value = {};
        value[event.target.name] = event.target.value;

        let {data} = this.state;
        if ( !data ) {
            data = {};
        }
        lodash.assign ( data, value )

        this.setState ( { data: data, dataChanged: true }  );
    }

    _handleChangeAttribute(event) {
        const {target} = event;

        const {data} = this.state;
        if ( !data.attributes ) {
            data.attributes = {};
        }
        if ( !data.attributes.data ) {
            data.attributes.data = [];
        }
        const attributeIdx = lodash.findIndex(data.attributes.data, { 'field_name': target.name });

        if ( -1 != attributeIdx ) {
            data.attributes.data[attributeIdx].value = target.value;
        } else {
            // We need to create this attribute
            if ( !data.attributes.data ) {
                data.attributes.data = [];
            }

            data.attributes.data.push({
                value: target.value,
                field_name: target.name
            })
        }

        this.setState({
            data: data,
            dataChanged: true
        })
    }

    postUsersStoreUpdate() {
        const users = UsersStore.getUsers();
        this.setState({ users: users })
    }

    componentDidMount() {
        let {match} = this.props;

        if ( match && match.params && match.params.id ) {
            this.loadData( match.params.id )
        }

        const {user_role} = UserStore.getUser();

        if ( 'super-admin' == user_role ) {
            // Subscribe to update events
            UsersStore.subscribe( this.postUsersStoreUpdate );

            // Load data
            UsersStore.loadFromServer();
        }
    }


    componentWillUnmount() {
        const {user_role} = UserStore.getUser();

        if ( 'super-admin' == user_role ) {
            UsersStore.unsubscribe(this.postUsersStoreUpdate);
        }
    }

    render() {

        let {id, data, newPackage, dataChanged, users} = this.state;
        const { history } = this.props;
        const { from } = this.props.location.state || '/'
        const { fireRedirect, gotoAddPackage } = this.state;


        const attributes = data.attributes && data.attributes.length ? data.attributes : [];

        const user = UserStore.getUser();
        const {user_role} = user;

        return (
            <div className="content">
                <PageHeading title={id ? 'Manage Account' : 'Create Account'}
                        onSave={this._handleFormSubmit}
                             history={history}
                             dataChanged={dataChanged}
                             cancelGoToUrl="/subscriptions"
                />

                <div className="row">
                    <div className="col-lg-12">
                        <div className="innerAll bs-component">
                            <div className="form-horizontal" onSubmit={() => {return false;}}>

                            {id && typeof data.id == 'undefined' ? <PageLoading /> :

                                <div className="row">
                                    <div className="col-lg-5">
                                        <fieldset>
                                            <legend>Account details</legend>
                                            {user_role != 'super-admin' ?
                                                null
                                                :
                                                <Form.Group as={Row}>
                                                    <Form.Label column lg="3">Customer</Form.Label>
                                                    <Col lg="9">
                                                        {users === null ? <i className="fa fa-spin fa-spinner"/> :
                                                            <select className="form-control input-medium"
                                                                    name="user_id"
                                                                    value={this.state.data.user_id}
                                                                    onChange={this._handleChange}>
                                                                <option value={''}>- choose user -
                                                                </option>
                                                                {users.map((d) => <option key={'dou' + d.id}
                                                                                          value={d.id}>{d.username}</option>)}
                                                            </select>
                                                        }
                                                    </Col>
                                                </Form.Group>
                                            }

                                            <Form.Group as={Row}>
                                                <Form.Label column lg="3">Username</Form.Label>
                                                <Col lg="9">
                                                    {this.state.data.id ?
                                                    <p className="form-control-static">{this.state.data.sip_username}</p> :
                                                    <div><input type="text"
                                                           className="form-control input-medium"
                                                           name="sip_username"
                                                           placeholder="enter username"
                                                           autoComplete="off"
                                                           value={this.state.data.sip_username || ''}
                                                           onChange={this._handleChangeUsername}
                                                    />
                                                        <p className={'help-block'}>Only lowercase usernames accepted.</p>
                                                    </div>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row}>
                                                <Form.Label column lg="3">Password</Form.Label>
                                                <Col lg="9">
                                                    <input type="password"
                                                           className="form-control input-medium"
                                                           name="sip_password"
                                                           placeholder="enter password"
                                                           autoComplete="off"
                                                           value={this.state.data.sip_password || ''}
                                                           onChange={this._handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row}>
                                                <Form.Label column lg="3">Confirm Password</Form.Label>
                                                <Col lg="9">
                                                    <input type="password"
                                                           className="form-control input-medium"
                                                           name="sip_password_confirm"
                                                           placeholder="confirm password"
                                                           autoComplete="off"
                                                           value={this.state.passwordConfirmation || ''}
                                                           onChange={(ev) => { this.setState({passwordConfirmation: ev.target.value } ) }}
                                                    />
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row}>
                                                <Form.Label column lg="3">Device IMEI</Form.Label>
                                                <Col lg="9">
                                                    <div><input type="text"
                                                                className="form-control input-medium"
                                                                name="device_imei"
                                                                placeholder=""
                                                                autoComplete="off"
                                                                value={this.state.data.device_imei || ''}
                                                                onChange={this._handleChange}
                                                    />
                                                    </div>

                                                </Col>
                                            </Form.Group>

                                            {!this.state.data.id ? null :
                                            <Form.Group as={Row}>
                                                <Form.Label column lg="3">Software Version</Form.Label>
                                                <Col lg="9">
                                                    <div><input type="text"
                                                                className="form-control input-medium"
                                                                name="device_imei"
                                                                placeholder=""
                                                                autoComplete="off"
                                                                disabled={true}
                                                                value={this.state.data.software_version || ''}
                                                                onChange={this._handleChange}
                                                    />
                                                    </div>

                                                </Col>
                                            </Form.Group>
                                            }

                                            {/*<div className="form-group">
                                                <Form.Label column lg="3">Subscription Start</Form.Label>
                                                <Col lg="9">

                                                    <DatePicker
                                                        selected={this.state.data.subscription_start ? moment.unix(this.state.data.subscription_start) : null}
                                                        dateFormat="MMM D, YYYY"
                                                        onChange={(moment) => this._handleChange({
                                                            target: {
                                                                name: 'subscription_start',
                                                                value: moment.unix()
                                                            }
                                                        })}
                                                        className="form-control input-small"
                                                        placeholderText="Click to select date"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <Form.Label column lg="3">Subscription End</Form.Label>
                                                <Col lg="9">

                                                    <DatePicker
                                                        selected={this.state.data.subscription_end ? moment.unix(this.state.data.subscription_end) : null}
                                                        dateFormat="MMM D, YYYY"
                                                        onChange={(moment) => this._handleChange({
                                                            target: {
                                                                name: 'subscription_end',
                                                                value: moment.unix()
                                                            }
                                                        })}
                                                        className="form-control input-small"
                                                        placeholderText="Click to select date"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        minDate={this.state.data.subscription_start ? moment.unix(this.state.data.subscription_start) : moment()}
                                                    />
                                                </div>
                                            </div>*/}


                                            <Form.Group as={Row}>
                                                <Form.Label column lg="3">Do Not List</Form.Label>
                                                <Col lg="9">
                                                    <div className="marginT half">
                                                        <label className="text-weight-normal">
                                                        <input type="checkbox"
                                                               name="do_not_list"
                                                               className="marginT half"
                                                               checked={!!this.state.data.do_not_list}
                                                               onChange={(event) =>
                                                                   this._handleChange({
                                                                       target: {
                                                                           name: 'do_not_list',
                                                                           value: event.target.checked ? 1 : 0
                                                                       }
                                                                   })}
                                                               /> Enabled
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Form.Group>

                                            {/*<div className="form-group">
                                                <label htmlFor="select"
                                                       className="col-lg-3 control-label">Status</Form.Label>
                                                <Col lg="9">
                                                    <select className="form-control input-small"
                                                            name="is_active"
                                                            value={this.state.data.is_active}
                                                            onChange={this._handleChange}>
                                                        <option value="1">Active</option>
                                                        <option value="0">Suspended</option>
                                                    </select>

                                                </div>
                                            </div>*/}

                                            {/*<Form.Group as={Row} className={classNames({
                                                "formActions": true
                                            })}>
                                                <div className="col-lg-9 col-lg-offset-3">
                                                    <button className={classNames({
                                                        "btn btn-light marginR half": true
                                                    })} onClick={this.props.history.goBack}>Cancel
                                                    </button>
                                                    <button type="submit" className={classNames({
                                                        "btn btn-warning": true
                                                    })}>Save
                                                    </button>
                                                </div>
                                            </Form.Group>*/}
                                        </fieldset>
                                    </div>

                                    <div className="col-lg-5">
                                        <fieldset>
                                            <legend>Attributes</legend>
                                            <Attributes subscription={this.state.data} onChange={this._handleChangeAttribute} />
                                        </fieldset>
                                    </div>
                                </div>
                            }
                            </div>

                            {fireRedirect && (
                                <Redirect to={'/subscriptions'}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManageSubscription
