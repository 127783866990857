import React from 'react';
import ApiClient from "../../lib/ApiClient";

import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';

import shortid from 'shortid'
import _ from 'lodash'
import Steps from 'rc-steps'
import classNames from 'classnames'
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Select from "react-select";
import makeAnimated from 'react-select/lib/animated';

import RegionFilter from './RegionFilter'
import UsersFilter from "./UsersFilter";
import Notifications from "../../lib/Notifications";

class BroadcastForm extends React.Component {

    constructor(props) {
        super(props)

        this.setCurrentStep = (currentStep) => this.setState({currentStep});

        this.state = {
            recipientsType: 1,
            recipientsRegion: null,
            recipientsList: null,
            recipientsCount: null,

            sent: false,
            sending: false,
            sendingUid: null,

            broadcastLevel: 1,
            broadcastTitle: '',
            broadcastMessage: '',

            currentStep: 0,

            regions: null,
            users: null,
        }
    }

    componentDidMount() {
        this.loadRecipientsData()
    }

    loadRecipientsData() {
        const {
            recipientsType,
            recipientsRegion,
            recipientsList
        } = this.state;

        this.setState({
            sending: true
        });

        const regions = _.flatMap(recipientsRegion, o => o.value)
        const users   = _.flatMap(recipientsList, o => o.value)

        ApiClient.get({
            endpoint: '/api/v1/system/broadcast/recipients',
            query: {
                type: recipientsType,
                region: regions && regions.join(','),
                users: users && users.join(','),
            }
        }).then((res) => {
            this.setState({
                sending: false,
                recipientsCount: res.body.count || 0,

                regions: (res.body.filters && res.body.filters.regions) || null,
                users: (res.body.filters && res.body.filters.users) || null,

            })

        }, (err) => {
            this.setState({
                sending: false,
            })
        });
    }

    prevStep = () => {
        let {currentStep} = this.state;
        if ( currentStep > 0 ) {
            currentStep--;
        }
        this.setState({currentStep});
    };

    nextStep = () => {
        let {currentStep} = this.state;
        if ( currentStep < 2 ) {
            currentStep++;
        }
        this.setState({currentStep});
    };

    handleRecipientChange = (value) => {
        this.setState({
            recipientsType: value,
            recipientsCount: null,

        }, () => {
            if ( -1 !== [1,2].indexOf(this.state.recipientsType) ) {
                this.loadRecipientsData()
            }
        })
    }

    _handleBroadcastFormChange = field => (event) => {
        const { target: { value } } = event;
        const key = 'broadcast' + field[0].toUpperCase() + field.substr(1)

        // Should work ?
        this.setState({[key]: value})
    };

    _handleBroadcastLevelChange = (value) => {
        const key = 'broadcastLevel'

        // Should work ?
        this.setState({[key]: value})
    };

    _handleBroadcastSendMessage = (event) => {

        const {
            broadcastMessage,
            broadcastTitle,
            broadcastLevel,
            broadcastUsers,

            recipientsType,
            recipientsRegion,
            recipientsList,
        } = this.state;

        if (!broadcastTitle || !broadcastMessage) {
            return;
        }

        // Prepare regions
        const regions = _.flatMap(recipientsRegion, o => o.value)
        const users = _.flatMap(recipientsList, o => o.value)

        // Mark state accordingly
        const sendingUid = shortid.generate();
        this.setState({
            sendingUid,
            sending: true,
            sendingDone: 0,
            sendingTotal: 0,
            sendingPercent: 0,
        })

        // Start interval to check for sending progress
        this.statusCheckInterval = setInterval(() => this._checkSendingStatus(), 1000);


        // Send the message
        ApiClient.post({
            endpoint: '/api/v1/system/broadcast/new',
            data: {
                uid: sendingUid,
                message: broadcastMessage,
                title: broadcastTitle,
                level: broadcastLevel,

                users: users.join(','),
                region: regions.join(','),
                type: recipientsType,
            }
        }).then((res) => {
            if ( this.statusCheckInterval ) {
                clearInterval(this.statusCheckInterval)
            }

            this.setState({
                sending: false,
                sent: true,
                sendingDone: 0,
                sendingTotal: 0,
                sendingPercent: 0,
            }, () => {
                setTimeout(() => window.location = window.location, 6000)
            })

        }, (err) => {
            this.setState({
                sending: !this.state.sending
            })

            Notifications.showNotific8('An unexpected error has occured while sending the ' +
                'announcement. Refresh the page and try again. If the problem persists, contact support.',
                'danger', { closeInSeconds: 6 } );

        });

        return false;
    };

    _checkSendingStatus = () => {
        ApiClient.get({
            endpoint: '/api/v1/system/broadcast/status',
            query: {
                uid: this.state.sendingUid,
            }
        }).then((res) => {

            const done  = res.body.done || 0;
            const total = res.body.total || 0;

            this.setState({
                sendingDone: res.body.done,
                sendingTotal: res.body.total,
                sendingPercent: res.body.total ? Math.ceil( (res.body.done * 100) / res.body.total) : 0,
            })

        }, (err) => {

        });
    };

    render() {

        const {
            sending,
            recipientsCount,
            regions,
            users,
            recipientsRegion,
            recipientsList,
            broadcastLevel,
        } = this.state;

        const selectRegions = [];
        if ( regions ) {
            for ( let i in regions ) {
                selectRegions.push({
                    value: regions[i].name,
                    count: regions[i].count,
                    label: regions[i].name + ' (' + regions[i].count + ')'
                })
            }
        }

        const usersOptions = [];
        if ( users ) {
            for ( let i in users ) {
                usersOptions.push({
                    value: users[i],
                    label: users[i],
                })
            }
        }

        return (

            <div className="broadcast-container">
                <Steps initial={0}
                       labelPlacement="vertical"
                       current={this.state.currentStep}>
                    <Steps.Step title="Recipients" description="Choose who receives the message" />
                    <Steps.Step title="Message" description="Announcement details" />
                    <Steps.Step title="Review" description="Review the message and Send" />
                </Steps>

                <div className={classNames({
                    'step-container': true,
                    'display-none': this.state.currentStep !== 0,
                })}>
                    <div>
                        <h4 className="text-center">Send announcement to</h4>

                        <div className="recipient-type-btn-group text-center marginTB">

                            <Button size="lg" variant={1 === this.state.recipientsType ? "dark" : "light"}
                                    onClick={() => this.handleRecipientChange(1)}>All users</Button>

                            <Button size="lg" variant={2 === this.state.recipientsType ? "dark" : "light"}
                                    onClick={(value) => this.handleRecipientChange(2)}>Online users</Button>

                            <Button size="lg" variant={3 === this.state.recipientsType ? "dark" : "light"}
                                     onClick={(value) => this.handleRecipientChange(3)}>By Region</Button>

                            <Button size="lg" variant={4 === this.state.recipientsType ? "dark" : "light"}
                                    onClick={(value) => this.handleRecipientChange(4)}>Selected users</Button>
                        </div>
                    </div>

                    {this.state.recipientsType !== 3 ? null :
                        <RegionFilter
                            regions={selectRegions}
                            selected={recipientsRegion}
                            onChange={(value) => {

                                if ( value ) {
                                    this.setState({
                                        recipientsRegion: value
                                    }, () => {
                                        this.loadRecipientsData()
                                    })
                                } else {
                                    this.setState({
                                        recipientsRegion: null
                                    }, () => {
                                        this.loadRecipientsData()
                                    })
                                }
                            }}
                            />
                    }

                    {this.state.recipientsType !== 4 ? null :
                        <UsersFilter
                            users={usersOptions}
                            selected={recipientsList}
                            onChange={(value) => {

                                if ( value ) {
                                    this.setState({
                                        recipientsList: value,
                                        recipientsCount: value.length,
                                    }, () => {

                                    })
                                } else {
                                    this.setState({
                                        recipientsList: [],
                                        recipientsCount: 0,
                                    }, () => {

                                    })
                                }
                            }}
                        />
                    }


                    <div className="recipients-details text-center marginTB">
                        {sending ?
                            <div className=" text-center ">Loading
                                &nbsp;
                                <i className={'fa fa-spin fa-spinner fa-1x '}/>
                            </div>
                            :
                            null === recipientsCount ? null :
                            <p className="lead">The message will be sent to a total of <strong>{recipientsCount}</strong> users.</p>
                        }
                    </div>

                    <div className="step-actions marginTB">

                        <Button disabled className="marginAll half" variant="light" onClick={this.prevStep}>
                            Back
                        </Button>

                        <Button className="marginAll half" variant="warning"
                                disabled={!this.state.recipientsCount}
                                onClick={this.nextStep}>
                            Next
                        </Button>
                    </div>
                </div>

                <div className={classNames({
                    'step-container': true,
                    'display-none': this.state.currentStep !== 1
                })}>
                    <Row>
                        <Col xs={{size: 10, offset: 2}}>
                            <p className="text-muted text-weight-300">
                                Enter the announcement details. All fields are required.
                            </p>
                        </Col>
                    </Row>
                    <Form.Group as={Row} className="mb-4">

                        <Form.Label className="text-right" column sm={2}>Level</Form.Label>
                        <Col sm={10}>

                            <ButtonToolbar>
                                <ToggleButtonGroup
                                    type="radio"
                                    name="level"
                                    onChange={this._handleBroadcastLevelChange}
                                    defaultValue={broadcastLevel}>

                                    <ToggleButton variant={1 === broadcastLevel ? "success" : "light"} value={1}>Normal</ToggleButton>
                                    <ToggleButton variant={2 === broadcastLevel ? "warning" : "light"} value={2}>Warning</ToggleButton>
                                    <ToggleButton variant={3 === broadcastLevel ? "danger" : "light"} value={3}>Critical</ToggleButton>
                                </ToggleButtonGroup>
                            </ButtonToolbar>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4">
                        <Form.Label className="text-right" column xs={2}>Subject</Form.Label>
                        <Col xs={10}>
                            <input type="text" className="form-control"
                                   value={this.state.broadcastTitle}
                                   onChange={this._handleBroadcastFormChange('title')} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4">
                        <Form.Label className="text-right" column xs={2}>Message</Form.Label>
                        <Col xs={10}>
                            <textarea className="form-control mb-3"
                                      rows="4"
                                      style={{height: 'auto'}}
                                      value={this.state.broadcastMessage}
                                      onChange={this._handleBroadcastFormChange('message')}></textarea>
                        </Col>
                    </Form.Group>

                    <div className="step-actions marginTB">

                        <Button className="marginAll half" variant="light" onClick={this.prevStep}>
                            Back
                        </Button>

                        <Button className="marginAll half"
                                variant="warning"
                                disabled={!this.state.broadcastTitle || !this.state.broadcastMessage}
                                onClick={this.nextStep}>
                            Next
                        </Button>
                    </div>
                </div>

                <div className={classNames({
                    'step-container': true,
                    'display-none': this.state.currentStep !== 2
                })}>
                    <div className="review-step">
                        <Form.Group as={Row} className="mb-2">
                            <Form.Label column xs={2} className="text-right">Recipients</Form.Label>
                            <Col xs={10}>
                                <p className="form-control-plaintext">
                                    <strong>{recipientsCount}</strong> users
                                </p>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-2">
                            <Form.Label column xs={2} className="text-right">Level</Form.Label>
                            <Col xs={10}>
                                <p className="form-control-plaintext">
                                    {[1,2,3].map((type) => {
                                        if ( 1 === type && 1 === broadcastLevel ) {
                                            return (<span className="text-success">Normal</span> );
                                        }

                                        if ( 2 === type && 2 === broadcastLevel ) {
                                            return (<span className="text-warning">Warning</span> );
                                        }

                                        if ( 3 === type && 3 === broadcastLevel ) {
                                            return (<span className="text-danger">Critical</span> );
                                        }
                                    })}
                                </p>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-2">
                            <Form.Label column xs={2} className="text-right">Subject</Form.Label>
                            <Col xs={10}>
                                <p className="form-control-plaintext">{this.state.broadcastTitle}</p>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-5">
                            <Form.Label column xs={2} className="text-right">Message</Form.Label>
                            <Col xs={10}>
                                <p className="form-control-plaintext"
                                   dangerouslySetInnerHTML={{__html: this.state.broadcastMessage.replace("\n", "<br/>")}}/>
                            </Col>
                        </Form.Group>

                        {/*<Form.Group as={Row} className="mb-5">
                            <Form.Label column xs={2} className="text-right">Preview</Form.Label>
                            <Col xs={12}>
                                <div className={classNames([
                                    'message-preview',
                                    'message-' + broadcastLevel,
                                ])}>
                                    <div className="m-title">{this.state.broadcastTitle}</div>
                                    <div className="m-content" dangerouslySetInnerHTML={{__html: this.state.broadcastMessage.replace("\n", "<br/>")}}></div>
                                </div>
                            </Col>
                        </Form.Group>*/}

                        {!this.state.sending ?
                            !this.state.sent ?
                                <p className="lead text-center">Sending the announcement can take up to 5 minutes.
                                    The page must not be closed until the sending is completed.
                                </p>
                                :
                                <p className="lead text-center text-success">The message has been sent.
                                    Please wait for the page to reload.</p>
                            :
                            <div>
                                <p className="lead text-center">Sending the message, please wait..</p>
                                <ProgressBar
                                    label={`${this.state.sendingPercent}%`}
                                    variant={"info"}
                                    now={this.state.sendingPercent}/>
                            </div>
                        }
                    </div>

                    <div className="step-actions marginTB">

                        <Button className="marginAll half"
                                variant="light"
                                disabled={this.state.sending || this.state.sent}
                                onClick={this.prevStep}>
                            Back
                        </Button>

                        <Button className="marginAll half"
                                variant="danger"
                                disabled={this.state.sending || this.state.sent}
                                onClick={this._handleBroadcastSendMessage}>
                            Send message
                        </Button>
                    </div>
                </div>
            </div>

        );
    }
}

export default BroadcastForm;