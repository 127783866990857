import React,{Component, PureComponent} from "react";
import lodash from 'lodash';
import Dropzone from 'react-dropzone';

import { Tabs, Tab } from 'react-bootstrap';

import ApiClient from './../../common/lib/ApiClient';
import SettingsStore from './../../common/stores/Settings';

import PageLoading from './../../common/components/PageLoading';
import BroadcastForm from '../../common/components/broadcast/BroadcastForm';
import Notifications from '../../common/lib/Notifications';

import PanelLicenseInfo from './license-info';
import EavAttributes from './eav-attributes';
import bootbox from 'bootbox';
import UsersStore from "../../common/stores/Users";

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

class SystemSettings extends React.Component {

    constructor(props) {
        super(props)

        this._handleChange          = this._handleChange.bind(this)
        this._handleFormSubmit      = this._handleFormSubmit.bind(this)
        this._handleMassSyncXmpp    = this._handleMassSyncXmpp.bind(this)
        this.triggerCreateUsers     = this.triggerCreateUsers.bind(this)
        this.triggerDeleteUsers     = this.triggerDeleteUsers.bind(this)
        this.triggerDeleteDebugLogs = this.triggerDeleteDebugLogs.bind(this)
        this.postSettingsUpdated    = this.postSettingsUpdated.bind(this)
        this.handleKeywordDetect    = this.handleKeywordDetect.bind(this)

        this.state = {
            showMagicMenu: !!window.magicMenu,
            dataChanged: false,
            uploadingFile: false,
            uploadingCertificate: false,
            loadingBulkXmpp: false,
            loadingCreateUsers: false,
            loadingDeleteUsers: false,
            loadingDeleteLogs: false,
            new_sha1_fingerprint: null,
            data: {

            }
        }
    }

    loadData() {
        ApiClient.get({
            endpoint: '/api/v1/system/settings'
        }).then(res => {
            this.setState({
                dataChanged: false,
                data: res.body.data
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    _handleSaveSha1Fingerprint (event) {
        if ( event ) { // real form submission
            event.preventDefault();
        }

        this.setState({
            savingForm: true
        });

        let { data } = this.state;
        ApiClient.put({
            endpoint: '/api/v1/system/settings',
            data: { apk_sha1_fingerprint: this.state.new_sha1_fingerprint }
        }).then((res) => {

            // Only if it's success call we alter the data
            var datas = res && res.body && res.body.data ? res.body.data : null;

            Notifications.showNotific8(
                'The system settings have been updated',
                'success', {
                    closeInSeconds: 10
                });

            let {data} = this.state;
            if ( !data ) {
                data = {};
            }
            lodash.assign ( data, { apk_sha1_fingerprint: datas.apk_sha1_fingerprint } )

            this.setState ( { data: data, new_sha1_fingerprint: null, savingForm: false, dataChanged: false }  );

            SettingsStore.setConfig( data );

        }, (err) => {
            ApiClient.onError(err)
        });
    }

    _handleFormSubmit (event) {
        if ( event ) { // real form submission
            event.preventDefault();
        }

        this.setState({
            savingForm: true
        });

        let { data } = this.state;
        ApiClient.put({
            endpoint: '/api/v1/system/settings',
            data: data
        }).then((res) => {

            // Only if it's success call we alter the data
            var data = res && res.body && res.body.data ? res.body.data : null;

            Notifications.showNotific8(
                'The system settings have been updated. Please wait for the page to refresh.',
                'success', {
                    closeInSeconds: 10
                });

            this.setState({
                data: data,
                savingForm: false,
                dataChanged: false
            }, () => {
                SettingsStore.setConfig( data );

                // Refresh page
                window.location = window.location;
            })

        }, (err) => {
            ApiClient.onError(err)
        });
    }

    _handleChange(event, callback) {
        let value = {};
        value[event.target.name] = event.target.value;

        let {data} = this.state;
        if ( !data ) {
            data = {};
        }
        lodash.assign ( data, value )

        this.setState ( { data: data, dataChanged: true }, callback  );
    }

    _handleSha1Change(event) {
        let new_sha1_fingerprint = event.target.value;
        new_sha1_fingerprint = lodash.toUpper( new_sha1_fingerprint );
        this.setState({ new_sha1_fingerprint });
    }

    _handleFileDelete(platform) {
        ApiClient.delete({
            endpoint: '/api/v1/system/application-file/' + platform
        }).end(() => {
            this.loadData();
        })
    }



    onDrop (files) {

        if (!files || !files[0]) {
            return false;
        }

        const req = ApiClient.getPostRequestObject('/api/v1/system/settings/logo');
        req.attach('site_logo', files[0]);

        req.end((err, res) => {
            if ( err || !res.ok ) {
                Notifications.showNotific8(
                    'The new logo could not be uploaded: ' + (res.body && res.body.error ? res.body.error : 'Server error' ),
                    'error', {
                        closeInSeconds: 10
                    });

                return true;
            }

            this._handleChange({
                target: {
                    name: 'site_logo',
                    value: res.body.data.site_logo
                }
            })
        })
    }

    _handleMassSyncXmpp() {
        // You sure?
        let confirm = window.confirm('Are you really sure you want to continue with this action? Unexpected and undoable issues can occur. ')
        if ( !confirm ) {
            return false;
        }

        this.setState({
            loadingBulkXmpp: true
        })
        ApiClient.get({
            endpoint: '/api/v1/system/push-users-to-ejabberd'
        }).then((res) => {
            Notifications.showNotific8(
                'The accounts have been pushed to Ejabberd',
                'success', {
                    closeInSeconds: 10
                });

            this.setState({
                loadingBulkXmpp: false
            })
        }, (err) => {
            ApiClient.onError(err)
        });
    }

    onDropApnsCert (files) {
        if (!files || !files[0]) {
            return false;
        }

        // Uploading
        this.setState( { uploadingCertificate: true } )

        // do whatever you want with the file content
        const req = ApiClient.getPostRequestObject('/api/v1/system/application-apns-cert');

        req.query('platform=ios')
            .attach('file', files[0])
            .end((err, res) => {

                if ( err || !res.ok ) {
                    Notifications.showNotific8(
                        'Error occured during upload: ' + (res.body && res.body.error ? res.body.error : 'Server error' ),
                        'error', {
                            closeInSeconds: 10
                        });

                    this.setState({ uploadingCertificate: false });

                    return true;
                }

                Notifications.showNotific8(
                    'The APNs certificate has been applied',
                    'success', {
                        closeInSeconds: 10
                    });

                let {data} = this.state;
                lodash.assign ( data, res.body.data )

                this.setState({ data, uploadingCertificate: false })
            })
    }

    onDropIos (files) {
        if (!files || !files[0]) {
            return false;
        }

        // Uploading
        this.setState( { uploadingFile: true } )

        // do whatever you want with the file content
        const req = ApiClient.getPostRequestObject('/api/v1/system/application-file/ios');

        req.query('platform=ios')
            .attach('application_file', files[0])
            .end((err, res) => {

                if ( err || !res.ok ) {
                    Notifications.showNotific8(
                        'Error occured during upload: ' + (res.body && res.body.error ? res.body.error : 'Server error' ),
                        'error', {
                            closeInSeconds: 10
                        });

                    this.setState({ uploadingFile: false });

                    return true;
                }

                let {data} = this.state;
                lodash.assign ( data, res.body.data )

                this.setState({ data, uploadingFile: false })
            })
    }

    onDropApk (files) {
        if (!files || !files[0]) {
            return false;
        }

        // Uploading
        this.setState( { uploadingFile: true } )

        // do whatever you want with the file content
        const req = ApiClient.getPostRequestObject('/api/v1/system/application-file');

        req.query('platform=android')
            .attach('application_file', files[0])
            .end((err, res) => {

                if ( err || !res.ok ) {
                    Notifications.showNotific8(
                        'Error occured during upload: ' + (res.body && res.body.error ? res.body.error : 'Server error' ),
                        'error', {
                            closeInSeconds: 10
                        });

                    this.setState({ uploadingFile: false });

                    return true;
                }

                let {data} = this.state;
                lodash.assign ( data, res.body.data )

                this.setState({ data, uploadingFile: false })
            })
    }

    triggerDeleteDebugLogs() {
        bootbox.confirm({
            title: 'Confirm bulk delete debug logs',
            message: 'You are going to remove all the debug logs in the system, which means you will not be able to debug previous bugs. '
            + 'This function is intended purely for testing reasons. Are you sure you want to continue?',
            callback: function(result) {

                if ( !result ) {
                    return result;
                }

                this.setState({
                    loadingDeleteLogs: true
                })
                ApiClient.get({
                    endpoint: '/api/v1/system/bulk-delete-logs'
                }).then((res) => {
                    Notifications.showNotific8(
                        'The debug logs have been deleted',
                        'success', {
                            closeInSeconds: 10
                        });

                    this.setState({
                        loadingDeleteLogs: false
                    })
                }, (err) => {
                    ApiClient.onError(err)
                });

            }.bind(this),

            buttons: {
                cancel: {
                    label: 'Cancel',
                    className: 'btn-danger'
                },
                confirm: {
                    label: 'Delete logs',
                    className: 'btn-default'
                }
            }
        });
        // ======
    }

    triggerDeleteUsers() {
        bootbox.confirm({
            title: 'Confirm bulk users delete',
            message: 'You are going to remove possibly all accounts in the web panel and in Kamailio. '
            + 'This function is intended purely for testing reasons. Are you sure you want to continue?',
            callback: function(result) {

                if ( !result ) {
                    return;
                }
                // Let's ask
                bootbox.prompt({
                    title: 'Enter the username prefix if you want to delete users based on username. Enter \'all\' to delete all users.',
                    inputType: 'text',
                    callback: function( result ) {

                        if ( !result ) {
                            return result;
                        }
                        this.setState({
                            loadingDeleteUsers: true
                        })
                        ApiClient.get({
                            endpoint: '/api/v1/system/bulk-delete-users?prefix=' + ( result )
                        }).then((res) => {
                            Notifications.showNotific8(
                                'The user accounts have been deleted',
                                'success', {
                                    closeInSeconds: 10
                                });

                            this.setState({
                                loadingDeleteUsers: false
                            })
                        }, (err) => {
                            ApiClient.onError(err)
                        });
                    }.bind(this),

                    buttons: {
                        cancel: {
                            label: 'Cancel',
                            className: 'btn-default'
                        },
                        confirm: {
                            label: 'Delete users',
                            className: 'btn-primary'
                        }
                    }
                })

            }.bind(this),

            buttons: {
                cancel: {
                    label: 'Cancel',
                    className: 'btn-danger'
                },
                confirm: {
                    label: 'Continue',
                    className: 'btn-default'
                }
            }
        });
        // ======
    }

    triggerCreateUsers() {
        bootbox.confirm({
            title: 'Confirm bulk users create',
            message: 'You are going to create a batch of random accounts in the web panel and in Kamailio. '
            + 'This function is intended purely for testing reasons. Are you sure you want to continue?',
            callback: function(result) {

                if ( !result ) {
                    return;
                }

                // Let's ask
                bootbox.prompt({
                    title: 'Enter the number of random accounts you want to create',
                    inputType: 'number',
                    callback: function( result ) {

                        if ( !result ) {
                            return result;
                        }

                        this.setState({
                            loadingCreateUsers: true
                        })
                        ApiClient.get({
                            endpoint: '/api/v1/system/bulk-create-users?count=' + parseInt( result )
                        }).then((res) => {
                            Notifications.showNotific8(
                                'The random user accounts have been created',
                                'success', {
                                    closeInSeconds: 10
                                });

                            this.setState({
                                loadingCreateUsers: false
                            })
                        }, (err) => {
                            ApiClient.onError(err)
                        });
                    }.bind(this),

                    buttons: {
                        cancel: {
                            label: 'Cancel',
                            className: 'btn-default'
                        },
                        confirm: {
                            label: 'Create users',
                            className: 'btn-primary'
                        }
                    }
                })

            }.bind(this),

            buttons: {
                cancel: {
                    label: 'Cancel',
                    className: 'btn-danger'
                },
                confirm: {
                    label: 'Continue',
                    className: 'btn-default'
                }
            }
        });
        // ======
    }

    handleKeywordDetect(event) {
        if (event.defaultPrevented) {
            return;
        }

        var key = event.key || event.keyCode;

        var kw = window.magicKeyword;
        var char = key.toString();
        window.magicKeyword = kw + char;

        if ( window.magicKeyword == 'magicmenu' ) {
            window.magicMenu    = true;

            this.setState({
                showMagicMenu: true
            })

            document.removeEventListener('keyup', this.handleKeywordDetect);
            return;
        }

        if ( window.magicKeyword.length >= 9 ) {
            window.magicKeyword = '';
        }

        window.magicMenu = false;
    }

    postSettingsUpdated() {
        const magicmenu = SettingsStore.getConfig().magicMenu;
        this.setState({ showMagicMenu: !!magicmenu })
    }

    componentDidMount() {
        document.title = "System Settings | " + SettingsStore.getConfig().site_name;

        document.addEventListener('keyup', this.handleKeywordDetect);

        // Subscribe to update events
        SettingsStore.subscribe( this.postSettingsUpdated );

        this.loadData();
    }


    componentWillUnmount() {
        SettingsStore.unsubscribe(this.postSettingsUpdated);
        document.removeEventListener('keyup', this.handleKeywordDetect);
    }

    render() {

        let showMagicMenu = false;
        if ( this.state.showMagicMenu ) {
            showMagicMenu = true;
        }

        let {data, dataChanged} = this.state;
        let dropzoneRef, dropzoneIos, dropzoneApnsCert;

        let sha1_fingerprint = this.state.new_sha1_fingerprint !== null ? this.state.new_sha1_fingerprint : this.state.data.apk_sha1_fingerprint;

        const { history } = this.props;

        return (
            <div className="content page-settings">
                <div className="clearfix">
                    <div className="help-block text-right pull-left marginB half"
                         style={{ visibility: dataChanged ? 'visible' : 'hidden', color: '#e74c3c'}}>
                        <i className="fa fa-exclamation-triangle fa-fw innerR half"/>
                        There are unsaved changes.
                    </div>
                </div>

                {typeof data.site_name == 'undefined' ? <PageLoading /> :
                    <Tabs defaultActiveKey={1} transition={false} id="system-settings">
                        <Tab eventKey={1} title={(<span>Panel settings</span>)}>
                            <div className="license-info innerAll half text-muted pull-right">
                                Web UI version: {this.state.data.application_version ? this.state.data.application_version : 'n/a'}
                            </div>

                            <div className="form-horizontal">
                            <fieldset>
                                <legend>System Settings</legend>

                                <Form.Group as={Row}>
                                    <Form.Label column lg="3">Site Name</Form.Label>
                                    <Col lg="9">
                                        <input type="text"
                                               className="form-control"
                                               name="site_name"
                                               placeholder=""
                                               autoComplete="off"
                                               value={this.state.data.site_name || ''}
                                               onChange={this._handleChange}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group  as={Row}>
                                    <Form.Label column lg="3">Site Logo</Form.Label>
                                    <Col lg="9">
                                        {!this.state.data.site_logo ?
                                            <div>
                                                <Dropzone
                                                    className="form-file-dropzone"
                                                    accept="image/png,image/jpg,image/jpeg"
                                                    multiple={false}
                                                    name="site_logo"
                                                    onDrop={this.onDrop.bind(this)}>
                                                    <p>Drag&drop your logo here, or click to select a file to upload.</p>
                                                </Dropzone>

                                                <p className="help-block">
                                                    Recommended height: 50px. Maximum width: 250px. <br/>Only JPG and PNG accepted.
                                                </p>
                                            </div>
                                            :
                                            <div>
                                                <div className="logo-preview">
                                                    <img src={this.state.data.site_logo} />
                                                </div>
                                                <div>
                                                    <a href="javascript:;" onClick={() => this._handleChange({target:{name:'site_logo', value:''}})}>Remove logo</a>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="border-top innerT semi-half">
                                    <Form.Label column lg="3">Application Protocol</Form.Label>
                                    <Col lg="9">
                                        <select name="protocol"
                                                className="form-control input-small"
                                                value={this.state.data.protocol}
                                                onChange={this._handleChange}
                                        >
                                            {/*<option value="http">HTTP</option>*/}
                                            <option value="https">HTTPS</option>
                                        </select>
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row}>
                                    <Form.Label column lg="3">Application Domain</Form.Label>
                                    <Col lg="9">
                                        <InputGroup className="mb-3">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="basic-addon3">
                                                    {this.state.data.protocol}://
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl type="text"
                                                         className="form-control"
                                                         name="app_domain"
                                                         placeholder=""
                                                         autoComplete="off"
                                                         value={this.state.data.app_domain || ''}
                                                         onChange={this._handleChange} />
                                        </InputGroup>
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row}>
                                    <Form.Label column lg="3">XMPP API Host</Form.Label>
                                    <Col lg="9">
                                        <InputGroup className="mb-3">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="basic-addon4">
                                                    {this.state.data.protocol}://
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl type="text"
                                                         className="form-control"
                                                         name="xmpp_host"
                                                         placeholder=""
                                                         autoComplete="off"
                                                         value={this.state.data.xmpp_host || ''}
                                                         onChange={this._handleChange} />
                                        </InputGroup>
                                    </Col>
                                </Form.Group>

                                {/*<div className="form-group">
                                    <label className="col-lg-3 control-label">Base URL</label>
                                    <div className="col-lg-9">

                                        <div className="input-group">
                                            <span className="input-group-addon">{this.state.data.protocol}://{this.state.data.app_domain}/</span>
                                            <input type="text"
                                                   className="form-control"
                                                   name="base_url"
                                                   placeholder=""
                                                   autoComplete="off"
                                                   value={this.state.data.base_url || ''}
                                                   onChange={this._handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>*/}

                                <div className="formActions form-group">
                                    <Col lg={{ span: 9, offset: 3 }}>
                                        {/*<button className="btn btn-light marginR half" onClick={this.props.history.goBack}>Cancel
                                         </button>*/}
                                        <button type="submit" className="btn btn-warning " onClick={this._handleFormSubmit}>Save settings</button>
                                    </Col>
                                </div>
                            </fieldset>
                            </div>
                        </Tab>

                        <Tab eventKey={2} title={(<span>Android application</span>)}>
                            <fieldset className="marginL">
                                <div className="marginB">


                                    <div className="marginB border-bottom innerB half">
                                        <span className="text-weight-bold">SHA1 Fingerprint:&nbsp;&nbsp;</span>
                                        <input type="text"
                                               className="form-control input-sm marginT half"
                                               name="apk_sha1_fingerprint"
                                               placeholder=""
                                               autoComplete="off"
                                               value={sha1_fingerprint || ''}
                                               onChange={this._handleSha1Change.bind(this)}
                                        />

                                        <button type="submit" className="btn btn-secondary btn-sm marginT half innerLR"
                                                disabled={!this.state.new_sha1_fingerprint}
                                                onClick={this._handleSaveSha1Fingerprint.bind(this)}>Save Fingerprint</button>
                                    </div>

                                    <div className="marginB half">
                                        <span className="text-weight-bold">Package Name:&nbsp;&nbsp;</span>
                                        {this.state.data.apk_package_name ? (this.state.data.apk_package_name) : '(n/a)'}
                                    </div>

                                    <div className="marginB half">
                                        <span className="text-weight-bold">Version:&nbsp;&nbsp;</span>
                                        {this.state.data.apk_version ? (this.state.data.apk_version + ' (' + this.state.data.apk_version_code + ')') : '(n/a)'}
                                    </div>

                                    <div>
                                        <Dropzone className="hidden"
                                                  ref={(node) => { dropzoneRef = node; }} onDrop={this.onDropApk.bind(this)}>
                                        </Dropzone>
                                    </div>

                                    <div className="marginB">
                                        <span className="text-weight-bold">MD5 Checksum:&nbsp;&nbsp;</span>
                                        {this.state.data.apk_md5_checksum ? (this.state.data.apk_md5_checksum) : '(n/a)'}
                                    </div>


                                    <div className="">

                                        <button type="button" className="btn btn-warning marginR half"
                                                onClick={() => { dropzoneRef.open() }}>
                                            <i className="fa fa-cloud-upload fa-fw"/> Upload APK
                                        </button>

                                        {this.state.uploadingFile ? <span className="marginR half"><i className="fa fa-spin fa-spinner"/></span> : null}

                                        {!this.state.data.apk_url ? null :
                                            <a href={this.state.data.apk_url}
                                               target="_blank"
                                               download={true}
                                               className="btn btn-link text-secondary marginR half">
                                                <i className="fa fa-cloud-download fa-fw"/> Download APK
                                            </a>
                                        }

                                        {!this.state.data.apk_url ? null :
                                            <button type="button" className="btn btn-link text-secondary "
                                                    onClick={this._handleFileDelete.bind(this, 'android')}>
                                                <i className="fa fa-trash fa-fw"/> Delete APK
                                            </button>
                                        }
                                    </div>
                                </div>

                            </fieldset>
                        </Tab>



                        <Tab eventKey={3} title={(<span>iOS application</span>)}>
                            <fieldset className="marginL">
                                <div className="marginB">

                                    <div className="marginB half">
                                        <span className="text-weight-bold">Package Name:&nbsp;&nbsp;</span>
                                        {this.state.data.ipa_package_name ? (this.state.data.ipa_package_name) : '(n/a)'}
                                    </div>

                                    <div className="marginB half">
                                        <span className="text-weight-bold">Version:&nbsp;&nbsp;</span>
                                        {this.state.data.ipa_version ? (this.state.data.ipa_version + ' (' + this.state.data.ipa_version_code + ')') : '(n/a)'}
                                    </div>

                                    <div>
                                        <Dropzone className="hidden"
                                                  ref={(node) => { dropzoneIos = node; }} onDrop={this.onDropIos.bind(this)}>
                                        </Dropzone>
                                    </div>

                                    <div>
                                        <Dropzone className="hidden"
                                                  ref={(node) => { dropzoneApnsCert = node; }} onDrop={this.onDropApnsCert.bind(this)}>
                                        </Dropzone>
                                    </div>

                                    <div className="marginB">
                                        <span className="text-weight-bold">MD5 Checksum:&nbsp;&nbsp;</span>
                                        {this.state.data.ipa_md5_checksum ? (this.state.data.ipa_md5_checksum) : '(n/a)'}
                                    </div>


                                    <div className="">

                                        <button type="button" className="btn btn-warning marginR half"
                                                onClick={() => { dropzoneIos.open() }}>
                                            <i className="fa fa-cloud-upload fa-fw"/> Upload Application (.ipa)
                                        </button>

                                        {this.state.uploadingFile ? <span className="marginR half"><i className="fa fa-spin fa-spinner"/></span> : null}

                                        <button type="button" className="btn btn-link marginR half"
                                                onClick={() => { dropzoneApnsCert.open() }}>
                                            <i className="fa fa-certificate fa-fw"/> Upload APNs certificate (.pem)
                                        </button>

                                        {this.state.uploadingCertificate ? <span className="marginR half"><i className="fa fa-spin fa-spinner"/></span> : null}

                                        {!this.state.data.ipa_url ? null :
                                            <a href={this.state.data.ipa_url}
                                               target="_blank"
                                               className="btn btn-link text-secondary  marginR half">
                                                <i className="fa fa-cloud-download fa-fw"/> Download IPA
                                            </a>
                                        }

                                        {!this.state.data.ipa_url ? null :
                                            <button type="button" className="btn btn-link text-secondary "
                                                    onClick={this._handleFileDelete.bind(this, 'ios')}>
                                                <i className="fa fa-trash fa-fw"/> Delete IPA
                                            </button>
                                        }
                                    </div>
                                </div>

                            </fieldset>
                        </Tab>

                        {!this.state.data.license_key_info ? null :
                            <Tab eventKey={4} title={(<span>License key</span>)}>
                                <PanelLicenseInfo
                                    license={this.state.data.license_key_info}
                                    className="license-info"
                                />
                            </Tab>
                        }

                        <Tab eventKey={5} title={(<span>Custom attributes</span>)}>
                            <EavAttributes />
                        </Tab>



                        {!showMagicMenu ? null :
                            <Tab eventKey={10} title={(<span>Bulk actions (danger zone!)</span>)}>
                                <p className="text-weight-bold text-danger">Please use the functionality on this page
                                    ONLY IF YOU KNOW WHAT YOU ARE DOING.</p>
                                <p>Changes will be made which can break completely the functionality of the application
                                    and communications.</p>

                                <div className={'innerTB'}>
                                    <div className="inline-block marginR">
                                        <button type={'button'} className={'btn btn-danger'}
                                                onClick={this._handleMassSyncXmpp}
                                                disabled={this.state.loadingBulkXmpp}>
                                            {this.state.loadingBulkXmpp ? <i className="fa fa-fw fa-spin fa-spinner"/> :
                                                <i className={'fa fa-fw fa-bolt'}/>} Mass sync Ejabberd users
                                        </button>
                                    </div>

                                    <div className="inline-block marginR">
                                        <button type={'button'} className={'btn btn-danger'}
                                                onClick={this.triggerCreateUsers}
                                                disabled={this.state.loadingCreateUsers}>
                                            {this.state.loadingCreateUsers ?
                                                <i className="fa fa-fw fa-spin fa-spinner"/> :
                                                <i className={'fa fa-fw fa-user-plus'}/>} Create random subscriptions
                                        </button>
                                    </div>

                                    <div className="inline-block marginR">
                                        <button type={'button'} className={'btn btn-danger'}
                                                onClick={this.triggerDeleteUsers}
                                                disabled={this.state.loadingDeleteUsers}>
                                            {this.state.loadingDeleteUsers ?
                                                <i className="fa fa-fw fa-spin fa-spinner"/> :
                                                <i className={'fa fa-fw fa-trash'}/>} Mass delete users
                                        </button>
                                    </div>

                                    <div className="inline-block marginR">
                                        <button type={'button'} className={'btn btn-danger'}
                                                onClick={this.triggerDeleteDebugLogs}
                                                disabled={this.state.loadingDeleteLogs}>
                                            {this.state.loadingDeleteLogs ?
                                                <i className="fa fa-fw fa-spin fa-spinner"/> :
                                                <i className={'fa fa-fw fa-file-archive-o'}/>} Mass delete debug logs
                                        </button>
                                    </div>
                                </div>
                            </Tab>
                        }
                    </Tabs>
                }

            </div>
        );
    }
}

export default SystemSettings
