import React from 'react';
import { Redirect } from 'react-router-dom';

import ApiClient from '../../common/lib/ApiClient';
import UserStore from './../../common/stores/User'

export default class LoginForm extends React.Component {

    constructor(props) {
        super(props)

        this.submit = this.submit.bind(this)
        this.updateUsername = this.updateUsername.bind(this)
        this.updatePassword = this.updatePassword.bind(this)

        this.state = {
            loading: false,
            username: '',
            password: '',
            error: false,
            errorMsg: null,
            redirectToReferrer: false
        }
    }

    submit(ev) {
        ev.preventDefault();

        this.setState({
            loading: true
        })

        ApiClient.post({
            endpoint: '/api/v1/auth/login',
            data: this.state
        }).then((res) => {

            /*this.setState({
                error: false,
                errorMsg: false,
                loading: false,
                username: '',
                password: '',
                redirectToReferrer: true
            }, function() {
                // call the store
                this.props.onLogin ( res.body.data.jwt )
            });*/

            this.props.onLogin( res.body.data.jwt, () => {

                this.setState({
                    error: false,
                    errorMsg: false,
                    loading: false,
                    username: '',
                    password: '',
                    redirectToReferrer: true
                })
            } )

        }, (err) => {
            this.setState({
                loading: false,
                error: true,
                errorMsg: err.response && err.response.body && err.response.body.error ? err.response.body.error : 'API communication error'
            })
        } );

        /*this.props.onLogin(this.state);

        */
    }

    updateUsername(ev) {
        this.setState({ username: ev.target.value });
    }

    updatePassword(ev) {
        this.setState({ password: ev.target.value });
    }

    render() {

        const { error, errorMsg, loading } = this.state;

        const { from } = this.props.location && this.props.location.state ? this.props.location.state : { from: { pathname: '/' } }
        const { redirectToReferrer } = this.state

        if ( redirectToReferrer ) {
            window.location = '/';
            return false;
            return (
                <Redirect to='/'/>
            )
        }

        return (

            <form onSubmit={this.submit} className="form-horizontal">
                <fieldset>
                    {loading ? <div className={'blockingOverlay'}><div className={'loader'}><i className="fa fa-spin fa-spinner"/></div></div> : null }
                    {!error ? null :
                    <div className="text-danger text-center form-error innerB">
                        {errorMsg}
                    </div>
                    }
                    <div className="form-group">
                        <div className="">
                            <input type="text" className="form-control" id="inputUsername"
                                   onChange={this.updateUsername}
                                   placeholder="Username"
                                   autoComplete="off" />
                        </div>
                    </div>


                    <div className="form-group">
                        <div className="">
                            <input type="password" className="form-control" id="inputPassword"
                                   onChange={this.updatePassword}
                                   placeholder="Password"
                                   autoComplete="off" />
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary">Login</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        );
    }
}
