import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import Select, {createFilter} from "react-select";
import AsyncSelect from "react-select/lib/Async";
import makeAnimated from "react-select/lib/animated";

class UsersFilter extends React.Component {

    state = {
        options: [],
    }

    filterUsers = (input, callback) => {

        let {users} = this.props;
        let options = [];

        input = input.toLowerCase();
        if (input.length > 0) {
            options = users.filter(i =>
                i.label.toLowerCase().includes(input)
            );
        } else {
            options = users;
        }

        if ( options.length > 100 ) {

            const diff = users.length - 100;

            options = options.slice(0, 100);
            options.push({
                value: 'placeholder',
                label: ' .. and ' + (diff) + ' more',
                isDisabled: true,
            })
        }

        callback(options)
    };

    render() {
        const {
            users,
            selected
        } = this.props;


        return (
            <div className={classNames({
                'filter-users': true,
            })}>
                <h6 className="text-center">Choose users from list</h6>
                <AsyncSelect name={'filter-region'}
                        blurInputOnSelect={true}
                        components={makeAnimated()}
                        styles={{
                            control: (base) => ({
                                ...base,
                                minHeight: 41,
                            }),
                            multiValueLabel:(base) => ({
                                ...base,
                                padding: '5px 15px',
                                paddingLeft: 10,
                                fontSize: '100%',
                                borderRadius: 5, /*display: 'flex'*/
                            })
                        }}
                        placeholder={'Type to search'}
                         /*options={users}*/
                        className="stats-filter"
                        classNamePrefix="select"
                        isSearchable
                        isClearable={false}
                        isMulti
                        defaultValue={selected}
                        delimiter=","

                        onChange={this.props.onChange}

                         defaultOptions
                        filterOption={createFilter({ignoreAccents: false})}
                        loadOptions={this.filterUsers}
                />
            </div>
        )
    }
}

UsersFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
}

export default UsersFilter;