
import toastr from 'toastr';
var merge = require('merge');

var Notifications = new function () {

    this.showNotification = function( message, type, extraConfig ) {
        if ( !type ) {
            type = 'success';
        }

        var alertConfig = {
            container: '.page-content-container',
            place: 'prepend', // append or prepent in container
            type: type, // alert's type
            message: message, // alert's
            close: true, // make alert closable
            reset: false, // close all previouse alerts first
            focus: true, // auto scroll to the alert after shown
            closeInSeconds: 0 // auto close after defined seconds
            //icon: 'fa fa-check' // put icon class before the message
        };

        if ( extraConfig ) {
            alertConfig = merge.recursive(true, alertConfig, extraConfig);
        }

        window.config.appHandler.alert( alertConfig );
    }

    this.showNotific8 = function( message, type, extraConfig ) {
        if ( !type ) {
            type = 'success';
        }

        if ( 'danger' == type ) {
            type = 'error'
        }

        let shortCutFunction = type;

        let settings = {
            closeButton: true,
            positionClass: 'toast-top-center',
            showMethod: 'slideDown',
            hideMethod: 'slideUp',
            showDuration: 100,
            hideDuration: 200,
            toastClass: 'sc-toast'
        };

        settings.timeOut = extraConfig && extraConfig.closeInSeconds ? extraConfig.closeInSeconds * 1000 : 6000;

        toastr[shortCutFunction](message, null, settings);

    }
}()

export default Notifications;
