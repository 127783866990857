/**
 * App entry point
 */

// Polyfill
import 'babel-polyfill';

// Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router, Route, Switch
} from 'react-router-dom'
import 'bootstrap';

// Base styling
import './scss/style.scss';

// Stores
import SettingsStore from './common/stores/Settings';
import UserStore from './common/stores/User';

// Render the router
import App from './common/components/App';
import AppLogin from './common/components/AppLogin';

// ID of the DOM element to mount app on
const DOM_APP_EL_ID = 'app';

window.magicKeyword = '';
window.magicMenu    = false;




// Load application config and render UI only after it was loaded
SettingsStore.pullConfig(() => {

    let jwt = localStorage.getItem('jwt');

    if (jwt) {
        UserStore.loginUser(jwt);
    }

    renderApp();

})

function renderApp() {
    if (!UserStore.isLoggedIn() ) {

        ReactDOM.render((
            <Router>
                <AppLogin />
            </Router>
        ), document.getElementById(DOM_APP_EL_ID));

    } else {
        ReactDOM.render((
            <Router>
                <App />
            </Router>
        ), document.getElementById(DOM_APP_EL_ID));
    }
}