
import React, {Component} from "react";

class LicenseInfo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            application_key: props.application_key
        }
    }

    render() {
        const {license} = this.props;

        return (

            <div className="form-horizontal">
                <fieldset>
                    {!license.expiration_date ? null :
                    <div className="license-info innerAll half text-muted">
                        The license is <strong className="text-success">active</strong> and will expire on {license.expiration_date}
                    </div>
                    }

                    <div className="form-group margin-bottom-none row">
                        <label className="col-lg-3 control-label">Total active users</label>
                        <div className="col-lg-9">
                            <p className="form-control-static">{license.total_active_users}</p>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-3 control-label">Total allowed users</label>
                        <div className="col-lg-9">
                            <p className="form-control-static">{license.total_allowed_users}</p>
                        </div>
                    </div>

                    <div className="form-group marginT innerT border-top row">
                        <label className="col-lg-3 control-label">Application Key</label>
                        <div className="col-lg-9">
                            <textarea name="application_key" className="form-control">{license.application_key_file}</textarea>
                        </div>
                    </div>
                </fieldset>
            </div>
        )
    }
}

export default LicenseInfo
