import ApiClient from './../../common/lib/ApiClient';

var EventEmitter = require('events').EventEmitter;
var emitter = new EventEmitter();

var _subscriptions = [];

export default {
    getSubscriptions: function() {
        return _subscriptions.concat();
    },

    subscribe: function(callback) {
        emitter.on('update', callback);
    },

    unsubscribe: function(callback) {
        emitter.removeListener('update', callback);
    },

    setData: function ( subscriptions ) {
        _subscriptions = subscriptions;
        emitter.emit('update');
    },

    loadFromServer: function () {
        ApiClient.get({
            endpoint: '/api/v1/subscription'

        }).then(res => {
            _subscriptions = res.body.data;
            emitter.emit('update');

        }, (err) => {

        })

    }
};
