import React from 'react';
import UserStore from './../stores/User';
import SettingsStore from './../stores/Settings';
import {Route,Switch,Redirect} from 'react-router-dom'; 

import ApiClient from './../lib/ApiClient'

import Navbar from './Navbar';
import UserNav from './UserNav'


import PrivateAreaRouter from './PrivateAreaRouter';
import AdminAreaRouter from './AdminAreaRouter';
import LoginPage from '../../pages/login/page';
import HomePage from '../../pages/home/page';
import Logout from '../../common/components/Logout';

import UsersPage from '../../pages/users/users';
import ManageUser from '../../pages/users/manage-user';
import Profile from '../../pages/users/profile';

import SubscriptionsPage from '../../pages/subscriptions/subscriptions';
import ManageSubscription from '../../pages/subscriptions/manage-subscription';

import SystemSettings from '../../pages/system/settings';
import ResellerBuilds from '../../pages/system/builds';
import DebugLogs from '../../pages/system/debuglogs';
import Broadcast from '../../pages/broadcast/broadcast';

import Statistics from '../../pages/analytics/statistics';
import EventLog from '../../pages/analytics/event-log';
import AppVersionReport from "../../pages/analytics/app-version";
import Activity from "../../pages/analytics/activity";
import ActiveUsers from "../../pages/analytics/active-users-report";


class App extends React.Component {
    constructor(props) {
        super(props);

        this.postLogin          = this.postLogin.bind ( this );
        this.postLogout         = this.postLogout.bind( this )
        this.postConfigUpdate   = this.postConfigUpdate.bind( this );

        this.state = {
            user: UserStore.getUser(),
            config: SettingsStore.getConfig(),
            logoutRedirect: false
        }
    }

    postLogin() {
        let user = UserStore.getUser();

        this.setState( {
            user: UserStore.getUser()
        }, function() {
            if ( UserStore.isLoggedIn() ) {
                window.location = '/'
            }
        })
    }

    postLogout() {
        this.setState( {
            logoutRedirect: true
        });
    }

    postConfigUpdate() {
        this.setState({
            config: SettingsStore.getConfig()
        })
    }

    checkJwt() {
        if ( !localStorage.jwt || localStorage.jwt === '' ) {
            return;
        }

        ApiClient.post({
            endpoint: '/api/v1/auth/verify-jwt'

        }, localStorage.jwt ).then((res) => {

            UserStore.loginUser( res.body.data.jwt )

        }, (err) => {
            UserStore.logoutUser()
        });
    }

    componentWillMount() {
        this.checkJwt();
    }

    componentDidMount() {
        UserStore.subscribe(this.postLogout, 'logout');
        SettingsStore.subscribe(this.postConfigUpdate, 'update');
    }

    componentWillUnmount() {
        UserStore.unsubscribe(this.postLogout, 'logout');
        SettingsStore.unsubscribe(this.postConfigUpdate, 'update');
    }

    render() {
        let { children } = this.props;

        let style = {};
        if ( !UserStore.isLoggedIn() ) {
            style.paddingLeft = 0;
        }

        return (
            <div id="page-wrapper" className="page-wrapper" style={style}>
                <Route exact path="/" render={() => <Redirect to="/dashboard" />} />

                {/*<Navbar isLoggedIn={UserStore.isLoggedIn()} />*/}
                <PrivateAreaRouter path="*" component={Navbar} appConfig={this.state.config} />

                <div className="page-content" id="page-content-wrapper">
                    <div className="user-nav-wrapper">
                        <PrivateAreaRouter path="*" component={UserNav}/>
                    </div>

                    <Switch>
                        <Route path="/login" component={LoginPage} />
                        <Route path="/logout" component={Logout} />

                        <PrivateAreaRouter path="/dashboard" component={HomePage} />

                        <PrivateAreaRouter path="/user/me" exact component={Profile} />
                        <AdminAreaRouter path="/users" component={UsersPage} />
                        <AdminAreaRouter path="/user/:id?" component={ManageUser} />
                        <AdminAreaRouter path="/system/reseller-builds" component={ResellerBuilds} />
                        <AdminAreaRouter path="/system/broadcast" exact component={Broadcast} />
                        <AdminAreaRouter path="/system/settings" component={SystemSettings} />
                        <AdminAreaRouter path="/debuglog" component={DebugLogs} />
                        <AdminAreaRouter path="/analytics/statistics" component={Statistics} />
                        <AdminAreaRouter path="/analytics/event-log" component={EventLog} />
                        <AdminAreaRouter path="/analytics/report/app-version" component={AppVersionReport} />
                        <AdminAreaRouter path="/analytics/activity" component={Activity} />
                        <AdminAreaRouter path="/analytics/active-users" component={ActiveUsers} />

                        <PrivateAreaRouter path="/subscriptions" component={SubscriptionsPage} />
                        <PrivateAreaRouter path="/subscription/:id?" component={ManageSubscription} />

                        <Route component={NoMatch}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

const NoMatch = ({ location }) => (
    <div>
        <h3>The page you are looking for can not be found: <code>{location.pathname}</code></h3>
    </div>
)


//export default hot(App);
export default App;

