import React,{Component, PureComponent} from "react";
import { Link, Redirect } from 'react-router-dom';
import lodash from 'lodash';
import classNames from 'classnames';

import ApiClient from './../../common/lib/ApiClient';

import PageLoading from './../../common/components/PageLoading';
import PageHeading from './../../common/components/PageHeading';
import Notifications from '../../common/lib/Notifications';

import UserStore from './../../common/stores/User'

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


class Profile extends React.Component {

    constructor(props) {
        super(props)

        this._handleChange = this._handleChange.bind(this)
        this._handleFormSubmit = this._handleFormSubmit.bind(this)

        this.state = {
            id: props.computedMatch && props.computedMatch.params && props.computedMatch.params.id ? props.computedMatch.params.id : null,
            dataChanged: false,
            gotoAddPackage: false,
            passwordConfirmation: '',
            newPackage: { name: '', price: '', available_licenses: '', trial_duration: '', license_duration: ''},
            data: {}
        }
    }

    loadUserData() {

        ApiClient.get({
            endpoint: '/api/v1/user/me',
            query: {
                include: 'packages,builds'
            }

        }).then(res => {
            this.setState({
                dataChanged: false,
                data: res.body.data,
                passwordConfirmation: '',
                id: res.body.data.id
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    _handleFormSubmit (event) {
        if ( event ) { // real form submission
            event.preventDefault();
        }

        // Let's check if the two passwords match
        if ( !this.state.id ) {
            if ( !this.state.data.password ) {
                var error = 'The password is required when creating a user';
                Notifications.showNotific8(error, 'danger', { closeInSeconds: 6 } );
                return false;
            }
        }
        if ( this.state.data.password && this.state.data.password != this.state.passwordConfirmation ) {
            var error = 'The two passwords do not match';
            Notifications.showNotific8(error, 'danger', { closeInSeconds: 6 } );
            return false;
        }

        this.setState({
            savingForm: true
        });

        let { data } = this.state;


        ApiClient.put({
            endpoint: '/api/v1/user/me',
            query: { include: 'packages' },
            data: data
        }).then((res) => {

            // Only if it's success call we alter the data
            var data = res && res.body && res.body.data ? res.body.data : null;

            Notifications.showNotific8(
                'Your account data has been saved.',
                'success', {
                    closeInSeconds: 6
                });

            UserStore.loginUser ( res.body.data.jwt, () => {
                this.setState({
                    data: data,
                    passwordConfirmation: '',
                    savingForm: false,
                    fireRedirect: true
                })
            } )

        }, (err) => {
            ApiClient.onError(err)
        });


    }

    _handleChange(event) {
        let value = {};
        value[event.target.name] = event.target.value;

        let {data} = this.state;
        if ( !data ) {
            data = {};
        }
        lodash.assign ( data, value )

        this.setState ( { data: data, dataChanged: true }  );
    }

    componentDidMount() {
        this.loadUserData( )
    }

    render() {

        let {id, data, newPackage, dataChanged} = this.state;
        const { history } = this.props;
        const { from } = this.props.location.state || '/'
        return (
            <div className="content">
                <PageHeading title={'My Profile'}
                             onSave={this._handleFormSubmit}
                             history={history}
                             dataChanged={dataChanged}
                             cancelGoToUrl="/dashboard"
                />

                <Row>
                    <Col log="12">
                        <div className="innerAll bs-component">
                            <Form className="form-horizontal" onSubmit={() => {return false;}}>

                                {id && typeof data.id == 'undefined' ? <PageLoading /> :
                                    <Row>
                                        <Col lg="5">
                                            <fieldset>
                                                <legend>Account details</legend>
                                                <Form.Group as={Row}>
                                                    <Form.Label column sm="4">Username</Form.Label>
                                                    <Col sm="8">
                                                        <p className="form-control-static">{this.state.data.username}</p>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row}>
                                                    <Form.Label column sm="4">Email</Form.Label>
                                                    <Col sm="8">
                                                        <input type="text"
                                                               className="form-control"
                                                               name="email_address"
                                                               placeholder="Email"
                                                               autoComplete="off"
                                                               value={this.state.data.email_address || ''}
                                                               onChange={this._handleChange}
                                                        />
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row}>
                                                    <Form.Label column sm="4">Name</Form.Label>
                                                    <Col sm="4">
                                                        <input type="text"
                                                               className="form-control"
                                                               name="first_name"
                                                               placeholder="first name"
                                                               autoComplete="off"
                                                               value={this.state.data.first_name || ''}
                                                               onChange={this._handleChange}
                                                        />
                                                    </Col>
                                                    <Col sm="4">
                                                        <input type="text"
                                                               className="form-control"
                                                               name="last_name"
                                                               placeholder="last name"
                                                               autoComplete="off"
                                                               value={this.state.data.last_name || ''}
                                                               onChange={this._handleChange}
                                                        />
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row}>
                                                    <Form.Label column sm="4">Company</Form.Label>
                                                    <Col sm="8">
                                                        <input type="text"
                                                               className="form-control"
                                                               name="company"
                                                               placeholder="company name"
                                                               autoComplete="off"
                                                               value={this.state.data.company || ''}
                                                               onChange={this._handleChange}
                                                        />
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row}>
                                                    <Form.Label column sm="4">Website</Form.Label>
                                                    <Col sm="8">
                                                        <input type="text"
                                                               className="form-control"
                                                               name="website"
                                                               placeholder="website url"
                                                               autoComplete="off"
                                                               value={this.state.data.website || ''}
                                                               onChange={this._handleChange}
                                                        />
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row}>
                                                    <Form.Label column sm="4">Phone number</Form.Label>
                                                    <Col sm="8">
                                                        <input type="text"
                                                               className="form-control"
                                                               name="phone_number"
                                                               placeholder="phone number"
                                                               autoComplete="off"
                                                               value={this.state.data.phone_number || ''}
                                                               onChange={this._handleChange}
                                                        />
                                                    </Col>
                                                </Form.Group>


                                            </fieldset>

                                            <fieldset>
                                                <legend>Change password</legend>

                                                <Form.Group as={Row}>
                                                    <Form.Label column sm="4">Password</Form.Label>
                                                    <Col sm="8">
                                                        <input type="text"
                                                               name="password"
                                                               placeholder="enter new password"
                                                               autoComplete="off"
                                                               value={this.state.data.password || ''}
                                                               onChange={this._handleChange}
                                                               className="form-control"
                                                               required={!this.state.id}
                                                               minLength="6" />
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row}>
                                                    <Form.Label column sm="4">Confirm password</Form.Label>
                                                    <Col sm="8">
                                                        <input type="text"
                                                               name="confirm_password"
                                                               placeholder="confirm new password"
                                                               autoComplete="off"
                                                               value={this.state.passwordConfirmation || ''}
                                                               onChange={(ev) => { this.setState({passwordConfirmation: ev.target.value } ) }}
                                                               className="form-control"
                                                               required={!this.state.id}
                                                               minLength="6" />
                                                    </Col>
                                                </Form.Group>
                                            </fieldset>
                                        </Col>

                                    </Row>
                                }
                            </Form>

                            {fireRedirect && (
                                <Redirect to={'/dashboard'} />
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        );

        const { fireRedirect, gotoAddPackage } = this.state;
    }
}

export default Profile
