import React,{Component, PureComponent} from "react";
import { Link, Redirect } from 'react-router-dom';
import lodash from 'lodash';
import classNames from 'classnames';

import ApiClient from './../../common/lib/ApiClient';

import PageLoading from './../../common/components/PageLoading';
import PageHeading from './../../common/components/PageHeading';
import Notifications from '../../common/lib/Notifications';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


class ManageUser extends React.Component {

    constructor(props) {
        super(props)

        this._handleChange = this._handleChange.bind(this)
        this._handleFormSubmit = this._handleFormSubmit.bind(this)

        this.state = {
            id: props.computedMatch && props.computedMatch.params && props.computedMatch.params.id ? props.computedMatch.params.id : null,
            dataChanged: false,
            gotoAddPackage: false,
            passwordConfirmation: '',
            data: {
                is_active: 1,
                is_banned: 0
            }
        }
    }

    loadUserData(userId) {
        if ( !userId ) {
            return false;
        }

        ApiClient.get({
            endpoint: '/api/v1/user/' + userId,
            query: {
                include: 'subscriptions'
            }

        }).then(res => {
            this.setState({
                dataChanged: false,
                data: res.body.data,
                passwordConfirmation: '',
                id: res.body.data.id
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    _handleFormSubmit (event) {
        if ( event ) { // real form submission
            event.preventDefault();
        }

        // Let's check if the two passwords match
        if ( !this.state.id ) {
            if ( !this.state.data.password ) {
                var error = 'The password is required when creating a user';
                Notifications.showNotific8(error, 'danger', { closeInSeconds: 6 } );
                return false;
            }
        }
        if ( this.state.data.password && this.state.data.password != this.state.passwordConfirmation ) {
            var error = 'The two passwords do not match';
            Notifications.showNotific8(error, 'danger', { closeInSeconds: 6 } );
            return false;
        }

        this.setState({
            savingForm: true
        });

        let { data } = this.state;

        // What kind of submit?
        if ( this.state.id ) {

            ApiClient.put({
                endpoint: '/api/v1/user/' + this.state.id,
                query: { include: 'subscriptions' },
                data: data
            }).then((res) => {

                // Only if it's success call we alter the data
                var data = res && res.body && res.body.data ? res.body.data : null;

                Notifications.showNotific8(
                    'The user data has been saved',
                    'success', {
                        closeInSeconds: 6
                    });

                this.setState({
                    data: data,
                    passwordConfirmation: '',
                    savingForm: false,
                    fireRedirect: true
                }, function() {
                    // Show success

                    // Let parent know about the change
                    if ( this.props.onUserDataChanged ) {
                        this.props.onUserDataChanged( this.state.data );
                    }
                })

            }, (err) => {
                ApiClient.onError(err)
            });

        } else {

            const {newPackage} = this.state;
            data.newPackage = newPackage;

            ApiClient.post({
                endpoint: '/api/v1/user',
                query: { include: 'subscriptions' },
                data: data
            }).then((res) => {

                // Only if it's success call we alter the data
                var data = res && res.body && res.body.data ? res.body.data : null;

                Notifications.showNotific8(
                    'The user has been added to the system',
                    'success', {
                        closeInSeconds: 6
                    });

                this.setState({
                    passwordConfirmation: '',
                    savingForm: false,
                    fireRedirect: true
                }, function() {
                    // Show success

                    // Let parent know about the change
                    if ( this.props.onUserDataChanged ) {
                        this.props.onUserDataChanged( this.state.data );
                    }
                })

            }, (err) => {
                ApiClient.onError(err)
            });
        }
    }

    _handleChange(event) {
        let value = {};
        value[event.target.name] = event.target.value;

        let {data} = this.state;
        if ( !data ) {
            data = {};
        }
        lodash.assign ( data, value )

        this.setState ( { data: data, dataChanged: true }  );
    }

    componentDidMount() {
        let {computedMatch} = this.props;

        if ( computedMatch && computedMatch.params && computedMatch.params.id ) {
            this.loadUserData( computedMatch.params.id )
        }
    }

    render() {

        let {id, data, newPackage, dataChanged} = this.state;
        const { history } = this.props;
        const { from } = this.props.location.state || '/'
        const { fireRedirect, gotoAddPackage } = this.state;

        // Generate random password
        var apikey = generatePassword();

        return (
            <div className="content">
                <PageHeading title={id ? 'Edit user' : 'Add user'}
                        onSave={this._handleFormSubmit}
                             history={history}
                             dataChanged={dataChanged}
                             cancelGoToUrl="/users"
                />
                {/*<p className="welcomeText">Fields marked with * are required.</p>*/}

                <div className="row">
                    <div className="col-lg-12">
                        <div className="innerAll bs-component">
                            <div className="form-horizontal" onSubmit={() => {return false;}}>

                            {id && typeof data.id == 'undefined' ? <PageLoading /> :
                                <div className="row">
                                    <div className="col-lg-5">
                                        <fieldset>

                                            {!id ? null :
                                            <Form.Group as={Row}>
                                                <Form.Label column sm="4">User ID</Form.Label>
                                                <Col sm="8">
                                                    <p className="form-control-static">{this.state.data.id}</p>
                                                </Col>
                                            </Form.Group>
                                            }

                                            <Form.Group as={Row}>
                                                <Form.Label column sm="4">Username</Form.Label>
                                                <Col sm="8">
                                                    <input type="text"
                                                           className="form-control"
                                                           name="username"
                                                           placeholder="Username"
                                                           autoComplete="off"
                                                           minLength="3"
                                                           value={this.state.data.username || ''}
                                                           onChange={this._handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row}>
                                                <Form.Label column sm="4">Email</Form.Label>
                                                <Col sm="8">
                                                    <input type="text"
                                                           className="form-control"
                                                           name="email_address"
                                                           placeholder="Email"
                                                           autoComplete="off"
                                                           value={this.state.data.email_address || ''}
                                                           onChange={this._handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row}>
                                                <Form.Label column sm="4">Name</Form.Label>
                                                <Col sm="4">
                                                    <input type="text"
                                                           className="form-control"
                                                           name="first_name"
                                                           placeholder="First name"
                                                           autoComplete="off"
                                                           value={this.state.data.first_name || ''}
                                                           onChange={this._handleChange}
                                                    />
                                                </Col>
                                                <Col sm="4">
                                                    <input type="text"
                                                           className="form-control"
                                                           name="last_name"
                                                           placeholder="Last name"
                                                           autoComplete="off"
                                                           value={this.state.data.last_name || ''}
                                                           onChange={this._handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row}>
                                                <Form.Label column sm="4">Company</Form.Label>
                                                <Col sm="8">
                                                    <input type="text"
                                                           className="form-control"
                                                           name="company"
                                                           placeholder="Company name"
                                                           autoComplete="off"
                                                           value={this.state.data.company || ''}
                                                           onChange={this._handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row}>
                                                <Form.Label column sm="4">Website</Form.Label>
                                                <Col sm="8">
                                                    <input type="url"
                                                           className="form-control"
                                                           name="website"
                                                           placeholder="http://example.com"
                                                           autoComplete="off"
                                                           value={this.state.data.website || ''}
                                                           onChange={this._handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row}>
                                                <Form.Label column sm="4">Phone number</Form.Label>
                                                <Col sm="8">
                                                    <input type="text"
                                                           className="form-control"
                                                           name="phone_number"
                                                           placeholder="Phone number"
                                                           autoComplete="off"
                                                           value={this.state.data.phone_number || ''}
                                                           onChange={this._handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="border-top innerT semi-half">
                                                
                                                <Form.Label column sm="4">
                                                    Allowed Accounts
                                                    <p className="help-block">The maximum number of allowed active accounts for this customer.</p>
                                                </Form.Label>
                                                <Col sm="8">
                                                    <div className="checkbox">
                                                        <input type="checkbox" className="margin-left-none"
                                                               checked={this.state.data.allowed_subscriptions === null}
                                                               onChange={(e) => this._handleChange({ target: { name: 'allowed_subscriptions', value: !e.target.checked ? 0 : null } } )}
                                                        /> <span className="marginL"> Unlimited licenses</span>
                                                    </div>
                                                    <input type="text"
                                                           className="form-control"
                                                           name="allowed_subscriptions"
                                                           placeholder=""
                                                           autoComplete="off"
                                                           value={this.state.data.allowed_subscriptions !== null ? this.state.data.allowed_subscriptions : ''}
                                                           onChange={this._handleChange}
                                                           disabled={this.state.data.allowed_subscriptions === null}
                                                    />

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="border-top innerT semi-half">
                                                <Form.Label column sm="4">Password</Form.Label>
                                                <Col sm="8">
                                                    <input type="password"
                                                           name="password"
                                                           placeholder="enter password"
                                                           autoComplete="off"
                                                           value={this.state.data.password || ''}
                                                           onChange={this._handleChange}
                                                           className="form-control"
                                                           required={!this.state.id}
                                                           minLength="6" />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row}>
                                                <Form.Label column sm="4">Confirm password</Form.Label>
                                                <Col sm="8">
                                                    <input type="password"
                                                           name="confirm_password"
                                                           placeholder="confirm password"
                                                           autoComplete="off"
                                                           value={this.state.passwordConfirmation || ''}
                                                           onChange={(ev) => { this.setState({passwordConfirmation: ev.target.value } ) }}
                                                           className="form-control"
                                                           required={!this.state.id}
                                                           minLength="6" />
                                                </Col>
                                            </Form.Group>

                                            {!id ? null :
                                            <Form.Group as={Row}>
                                                <Form.Label column sm="4">API Key</Form.Label>
                                                <Col sm="8">
                                                    <input type="text"
                                                           className="form-control"
                                                           name="api_key"
                                                           placeholder="API key"
                                                           autoComplete="off"
                                                           value={this.state.data.api_key || ''}
                                                           onChange={this._handleChange}
                                                           readOnly={true}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            }

                                            <input type="hidden" name="user_role" value={this.state.data.user_role ? this.state.data.user_role : 'client'}/>

                                            <Form.Group as={Row}>
                                                <Form.Label column sm="4">Status</Form.Label>
                                                <Col sm="8">
                                                    <select className="form-control"
                                                            name="is_active"
                                                            value={this.state.data.is_active}
                                                            onChange={this._handleChange}>
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>
                                                    </select>

                                                </Col>
                                            </Form.Group>

                                            {/*<div className="form-group">
                                                <label htmlFor="select"
                                                       className="col-sm-4 control-label">Banned</label>
                                                <div className="col-sm-8">
                                                    <select className="form-control"
                                                            name="is_banned"
                                                            value={this.state.data.is_banned}
                                                            onChange={this._handleChange}>
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                </div>
                                            </div>*/}

                                        </fieldset>
                                    </div>

                                </div>
                            }
                            </div>

                            {fireRedirect && (
                                <Redirect to={'/users'}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManageUser


class RowActions extends PureComponent {

    render() {
        let { id, idx } = this.props

        return (
            <div className="rowActions">
                <a href="javascript:;"
                   className="btn btn-danger btn-xs"
                   onClick={(event) => this.props.onDelete( idx ) }><i className="fa fa-trash fa-fw"/> </a>
            </div> );
    }
}

const generatePassword = () => {
    var length = 20,
        charset = "abcdefghijkmnpqrstuvwxyzACDEFGHJKLMNPQRSTUVWXYZ2345679",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    return retVal;
}
