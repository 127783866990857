import React from 'react';

import ApiClient from './../../common/lib/ApiClient'
import Notifications from '../../common/lib/Notifications';

class EavAttributes extends React.Component {
    constructor(props) {
        super(props);

        this.handleAddNew = this.handleAddNew.bind(this)

        this.state = {
            subscription: null
        }
    }

    handleSave(idx, applyToSubscriptions) {
        let { subscription } = this.state;

        if ( subscription[idx].id ) { // Update
            ApiClient.put({
                endpoint: '/api/v1/system/eav-attributes/' + subscription[idx].id,
                data: subscription[idx]
            }).then((res) => {

                Notifications.showNotific8(
                    'The attribute has been updated',
                    'success', {
                        closeInSeconds: 6
                    });

                // Reload attributes
                this.loadData()

            }, (err) => {
                ApiClient.onError(err)
            });

        } else { // Create
            if ( applyToSubscriptions ) {
                subscription[idx].apply_to_existing_subscriptions = true;
            }
            ApiClient.post({
                endpoint: '/api/v1/system/eav-attributes',
                data: subscription[idx]
            }).then((res) => {

                Notifications.showNotific8(
                    'The attribute has been created',
                    'success', {
                        closeInSeconds: 6
                    });

                // Reload attributes
                this.loadData()

            }, (err) => {
                ApiClient.onError(err)
            });
        }
    }

    handleDelete(idx) {
        // Unset the field in the array
        let {subscription} = this.state;
        const aId = subscription[idx].id
        subscription.splice(idx, 1)

        if ( aId ) {
            ApiClient.delete({
                endpoint: '/api/v1/system/eav-attributes/' + aId
            }).then((res) => {

                Notifications.showNotific8(
                    'The attribute has been deleted',
                    'success', {
                        closeInSeconds: 6
                    });

                // Reload attributes
                this.loadData()

            }, (err) => {
                ApiClient.onError(err)
            });

        } else {


            this.setState({
                subscription
            })
        }
    }

    handleChange(idx, {target}) {
        // Unset the field in the array
        let {subscription} = this.state;
        subscription[idx][target.name] = target.value;

        this.setState({
            subscription
        })
    }

    handleAddNew() {
        let {subscription} = this.state;
        subscription.unshift({
            label: '',
            field_name: '',
            field_type: 'text'
        })

        this.setState({
            subscription
        })
    }

    loadData() {
        ApiClient.get({
            endpoint: '/api/v1/system/eav-attributes',
            query: {
                entity_type: 'subscription'
            }

        }).then(res => {
            this.setState({
                subscription: res.body.data ? res.body.data : []
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        const {subscription} = this.state;
        if ( subscription === null ) {
            return <div className="innerAll half">Loading</div>;
        }

        return (
            <div className="attributes-editor">
                <fieldset className="">
                    <legend>Subscription attributes</legend>
                    <button className="btn btn-sm btn-warning marginB" onClick={this.handleAddNew}>
                        <i className="fal fa-plus marginR half"/> Add attribute</button>

                    {subscription.map((data, i) => <Attribute key={'sad' + i}
                                                       data={data}
                                                       onDelete={this.handleDelete.bind(this, i)}
                                                       onSave={this.handleSave.bind(this, i, false)}
                                                       onSaveApply={this.handleSave.bind(this, i, true)}
                                                       onChange={this.handleChange.bind(this,i)} />)}
                </fieldset>
            </div>
        )
    }
}

const Attribute = ({data, onChange, onDelete, onSave, onSaveApply}) => (
    <div className="attribute-fields card marginB bg-light">
        <div className="card-body">
            {/*<h5 className="card-title margin-top-none marginB">{data.label}</h5>*/}
            <div className="form-group row">
                <div className="col-sm-4">
                    <label className="control-label">Label</label>
                    <input type="text" className="form-control input-sm"
                           name="label"
                           placeholder="My Field"
                           value={data.label ? data.label : ''}
                           onChange={onChange} />
                </div>

                <div className="col-sm-4">
                    <label className="control-label">Name</label>
                    <input type="text" className="form-control input-sm"
                           placeholder="my_field"
                           name="field_name" value={data.field_name}
                           onChange={onChange} />
                </div>

                <div className="col-sm-4">
                    <label className="control-label">Default value</label>
                    <input type="text" className="form-control input-sm"
                           name="default_value"
                           value={data.default_value ? data.default_value : ''}
                           onChange={onChange} />
                </div>
             </div>

            <div className="form-group row">

                <div className="col-sm-4">
                    <label className="control-label">Type</label>
                    <select name="field_type" className="form-control input-sm"
                            value={data.field_type}
                            onChange={onChange}>
                        <option value="text">Text</option>
                        <option value="int">Number</option>
                        {/*<option value="select">Select / Options</option>*/}
                    </select>
                </div>

                <div className="col-sm-4">
                    <label className="control-label">Required</label>
                    <select name="is_required" className="form-control input-sm"
                            value={data.is_required ? 1 : 0}
                            onChange={onChange}>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>

                <div className="col-sm-4">
                    <label className="control-label">Active</label>
                    <select name="is_active" className="form-control input-sm"
                            value={data.is_active ? 1 : 0}
                            onChange={onChange}>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>
            </div>

            <div className="attribute-actions">
                <button className="btn btn-link text-primary marginR half" onClick={onSave}>Save</button>
                {data.id ? null : <button className="btn btn-link text-primary marginR half" onClick={onSaveApply}>Save & Apply</button> }
                <button className="btn btn-link text-danger" onClick={onDelete}>Delete</button>

                {data.id ? null :
                    <div className="innerTB half">
                        "Save & Apply" will automatically apply this attribute to existing subscriptions (which don't have this attribute yet)
                        and the "Default value" will be used for the initial value.
                    </div>
                }
            </div>
        </div>
    </div>
);

export default EavAttributes;