import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownItem from 'react-bootstrap/DropdownItem'
import { LinkContainer } from 'react-router-bootstrap'

import UserStore from './../stores/User'
import ApiClient from './../lib/ApiClient'
import ResellerBuilds from "../../pages/system/builds";

class UserNav extends React.Component {

    constructor(props) {
        super(props)

        this.fetchResellerData = this.fetchResellerData.bind(this)

        this.state = {
            health: null,
            builds: {}
        }
    }

    fetchResellerData() {
        // Fetch reseller data, like builds and others
        ApiClient.get({
            endpoint: '/api/v1/user/me',
            query: {
                include: 'packages,builds'
            }

        }).then(res => {
            // Prepare build info
            const sbuilds = res.body.data && res.body.data.builds && res.body.data.builds.data.length ? res.body.data.builds.data : [];

            let builds = {};
            for ( let i in sbuilds ) {

                if ( sbuilds[i].package_meta && sbuilds[i].package_meta.apk_url ) {
                    // Android
                    builds.android = sbuilds[i].package_meta;
                }
            }

            this.setState({builds})
        }, (err) => {
            // Exist silently

            return;
        })
    }

    componentDidMount() {
        ApiClient.get({
            endpoint: '/api/v1/system/health-check'
        }).then(res => {
            this.setState({
                health: res.body && res.body.data ? res.body.data : null
            }, () => this.fetchResellerData() )


        }, (err) => {})
    }

    render() {
        const {first_name} = UserStore.getUser();

        const title = (<span><i className="fa fa-user fa-fw"/> {first_name}</span>)

        return (
            <div>
                <nav>
                    <ul className="user-nav">
                        {/*<ResellerBuildDownload />*/}
                        {!this.state.builds.android ? null :
                        <li role="presentation">
                            <a href={this.state.builds.android.apk_url} style={{color: '#2780e3'}} target={'_blank'}>Download APK</a>
                        </li>
                        }

                        <li>
                        <LinkContainer to="/user/me">
                            <DropdownItem>My Profile</DropdownItem>
                        </LinkContainer>
                        </li>

                        <li>
                        <LinkContainer to="/logout">
                            <DropdownItem>Logout</DropdownItem>
                        </LinkContainer>
                        </li>
                    </ul>
                </nav>
                {!this.state.health ? null :
                <div className="alert text-danger health-alert">
                    <i className="fa fa-exclamation-triangle marginR half"/>
                    <strong>IMPORTANT: The panel health check has failed. Until the issues bellow are fixed, the web application may not work correctly:</strong><br/>
                    {this.state.health.errors.map(e =>  (<li>{e}</li>) )}
                </div>
                }
            </div>
        )
    }
}

export default UserNav
